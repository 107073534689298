const { COOKIES_POLICY_REQUIRED_COUNTRIES_ISO_CODES } = require("../../../constants/localization");

const CLOUDFRONT_MOBILE_HEADER = "cloudfront-is-mobile-viewer";
const CLOUDFRONT_TABLET_HEADER = "cloudfront-is-tablet-viewer";
const CLOUDFRONT_DESKTOP_HEADER = "cloudfront-is-desktop-viewer";
const CLOUDFRONT_COUNTRY_HEADER = "cloudfront-viewer-country";

const isMobileOrTablet = (req) => {
  return isMobile(req) || isTablet(req);
};

const isMobile = (req) => {
  const { headers } = req;
  return headers[CLOUDFRONT_MOBILE_HEADER] && headers[CLOUDFRONT_MOBILE_HEADER] === "true";
};

const isTablet = (req) => {
  const { headers } = req;
  return headers[CLOUDFRONT_TABLET_HEADER] && headers[CLOUDFRONT_TABLET_HEADER] === "true";
};

const isDesktop = (req) => {
  const { headers } = req;
  return headers[CLOUDFRONT_DESKTOP_HEADER] && headers[CLOUDFRONT_DESKTOP_HEADER] === "true";
};

const isCookiesPolicyRequiredCountryFromServer = (res) => {
  const cloudFrontCountry = res.getHeader(CLOUDFRONT_COUNTRY_HEADER);
  return doesCountryRequireStrictCookiePolicy(cloudFrontCountry);
};

const doesCountryRequireStrictCookiePolicy = (cloudFrontCountry) => {
  return cloudFrontCountry && COOKIES_POLICY_REQUIRED_COUNTRIES_ISO_CODES.includes(cloudFrontCountry);
};

module.exports = {
  isMobileOrTablet,
  isDesktop,
  isMobile,
  isTablet,
  isCookiesPolicyRequiredCountryFromServer,
  doesCountryRequireStrictCookiePolicy
};
