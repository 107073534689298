const mondaySecrets = require("@mondaydotcomorg/monday-secrets");

const store = mondaySecrets.getStore("homepage", process.env.CREDS_ENV || "development");
let _initializedStore;

const initStore = async () => {
  await store.init();
  _initializedStore = store;
};

const initStoreIfNeeded = async () => {
  if (!_initializedStore) {
    await initStore();
  }
};

const getStore = async() => {
  await initStoreIfNeeded();
  return _initializedStore;
};

const get = async (secretName) => {
  const secretsStore = await getStore();
  const secret = secretsStore.get(secretName);
  return secret || {};
};

const getSync = (secretName) => {
  if (!_initializedStore) {
    console.log(`getSync was called without initializing the store. Secret name: ${secretName}`);
    initStore();
  }
  const secret = store.getSync(secretName);
  return secret || {};
};

module.exports = {
  initStore,
  get,
  getSync
};
