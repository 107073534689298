const getHasStudentPlan = (options = {}) => {
  const { forcePricing, hasStudentPlanCookie } = options;

  // Enable us to force the student plan by query param.
  if (forcePricing === "student_plan") return true;

  // Platform indication.
  return hasStudentPlanCookie === "true";
};

module.exports = {
  getHasStudentPlan
};
