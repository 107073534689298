const REFERER_HEADER = "referer";
const CLOUDFRONT_VIEWER_COUNTRY_HEADER = "cloudfront-viewer-country";
const CLOUDFRONT_MOBILE_HEADER = "cloudfront-is-mobile-viewer";
const CLOUDFRONT_TABLET_HEADER = "cloudfront-is-tablet-viewer";
const CLOUDFRONT_DESKTOP_HEADER = "cloudfront-is-desktop-viewer";
const AB_TEST_EXACT_EVENT_NAME = "Ab-Test-Exact-Event-Name";

module.exports = {
  REFERER_HEADER,
  CLOUDFRONT_VIEWER_COUNTRY_HEADER,
  CLOUDFRONT_MOBILE_HEADER,
  CLOUDFRONT_TABLET_HEADER,
  CLOUDFRONT_DESKTOP_HEADER,
  AB_TEST_EXACT_EVENT_NAME
};
