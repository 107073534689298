import { AUTO } from "constants/cloudinary-asset-qualities";

const SHA256 = require("crypto-js/sha256");

const CLOUDINARY_CLOUD_NAME = process.env.CLOUDINARY_CLOUD_NAME;
const CLOUDINARY_BASE = `https://dapulse-res.cloudinary.com/`;
const CLOUDINARY_UNSECURE_BASE = `http://dapulse-res.cloudinary.com/`;
const CLOUDINARY_STATIC_FOLDER = process.env.CLOUDINARY_STATIC_FOLDER;
const CLOUDINARY_IMAGE_DIRECTORY = "image/";
const CLOUDINARY_VIDEO_DIRECTORY = "video/";
const CLOUDINARY_STATIC_FOLDER_NAME = "/static";
const GENERATOR_FEATURED_IMAGES_FOLDER = "Generator_featured images";

const CLOUDINARY_MEDIA_LIBRARY_USER_NAME = process.env.CLOUDINARY_MEDIA_LIBRARY_USER_NAME;
const CLOUDINARY_MEDIA_LIBRARY_SCRIPT = "https://media-library.cloudinary.com/global/all.js";

const CLOUDINARY_BASE_VIDEO_URL = `${CLOUDINARY_STATIC_FOLDER}/video/video-library`;
const CLOUDINARY_VIDEO_RESOURCE = "video";
const CLOUDINARY_IMAGE_UPLOADS_BASE_FOLDER = `${CLOUDINARY_STATIC_FOLDER}/uploads`;
const CLOUDINARY_IMAGE_RESOURCE = "image";

const CLOUDINARY_IMAGE_PREFIX = `${CLOUDINARY_BASE}${CLOUDINARY_IMAGE_DIRECTORY}`;
const CLOUDINARY_VIDEO_PREFIX = `${CLOUDINARY_BASE}${CLOUDINARY_VIDEO_DIRECTORY}`;

const LOW_RES_IMAGE_QUALITY_LEVEL = 1;
const IMAGE_DEFAULT_BLUR = 300;

const CLOUDINARY_IMAGE_FILE_TYPE = "IMAGE";
const CLOUDINARY_VIDEO_FILE_TYPE = "VIDEO";
const CLOUDINARY_GIF_FILE_TYPE = "GIF";
const CLOUDINARY_VIDEO_THUMBNAIL_FILE_TYPE = "VIDEO_THUMBNAIL";

const GIF_FILE_EXTENSION = "gif";
const JPG_FILE_EXTENSION = "jpg";

const GIF_TO_VIDEO_AUTO_TRANSFORMATION = "f_mp4,f_auto";
const VIDEO_THUMBNAIL_TRANSFORMATION = "so_0p";
const FALLBACK_DEFAULT_IMAGE_TRANSFORMATION_PARAM = "d";

const { addBasePathToUrl } = require("/services/community/url-service");
const { getFileExtension, replaceFileExtension } = require("server/services/files-service/files-service");

const PRODUCTION_CLOUDINARY_STATIC_FOLDER = "remote_mondaycom_static";
const STAGING_CLOUDINARY_STATIC_FOLDER = "remote_mondaystagingcom_static";

// Holds auto upload mappings configured in cloudinary account for use to migrate images to cloudinary from s3 buckets
const AutoUploadMappings = {
  "https://monday.com/static": PRODUCTION_CLOUDINARY_STATIC_FOLDER,
  "https://monday.com/Generator_featured images": GENERATOR_FEATURED_IMAGES_FOLDER,
  "https://mondaystaging.com/Generator_featured images": GENERATOR_FEATURED_IMAGES_FOLDER,
  "https://mondaystaging.com/static": STAGING_CLOUDINARY_STATIC_FOLDER
};

const AutoUploadMappingsKeys = Object.keys(AutoUploadMappings);

const defaultTransformationParams = {
  crop: "fill",
  gravity: "faces"
};

// For creating the url
const PresetKeysConvertion = {
  width: "w_",
  height: "h_",
  crop: "c_",
  gravity: "g_",
  aspectRatio: "ar_",
  radius: "r_",
  quality: "q_",
  blur: "e_blur:",
  raw: "raw",
  defaultImage: "d_"
};

const PresetKeysConvertionKey = Object.keys(PresetKeysConvertion);

// These presets are pre made by cloudinary and does not require any additional calculation (on-the-fly calculations)
// If changed - make sure to update the upload presets (both production and staging) in cloudinary account
const PresetTransformations = {
  SMALL_CARD: {
    width: 328,
    height: 225,
    ...defaultTransformationParams
  },
  FEATURE_CARD: {
    aspectRatio: "704:410",
    width: 704,
    height: 410,
    ...defaultTransformationParams
  },
  SMALL: {
    width: 128,
    ...defaultTransformationParams
  },
  MEDUIM: {
    width: 256,
    ...defaultTransformationParams
  },
  LARGE: {
    width: 512,
    ...defaultTransformationParams
  },
  XLARGE: {
    width: 1024,
    ...defaultTransformationParams
  },
  XXLARGE: {
    width: 2048,
    ...defaultTransformationParams
  },
  SMALL_ROUND: {
    radius: "max", // round
    width: 128,
    height: 128,
    ...defaultTransformationParams
  },
  TEMPLATE_COVER: {
    aspectRatio: "1766:768",
    ...defaultTransformationParams
  }
};

const extractPublicId = (src) => {
  if (!src) {
    return { publicId: src };
  }
  // checking if src is mapped in cloudinary for auto upload
  for (const key of AutoUploadMappingsKeys) {
    if (src.startsWith(key)) {
      // this url is for auto upload
      const id = src.substring(key.length);
      const newUrl = `${AutoUploadMappings[key]}${id}`;
      return { publicId: newUrl };
    }
  }
  return { isFetch: true, publicId: src }; // other urls that are not auto uploaded
};

const getCloudinaryMethodPostfix = (isFetch) => {
  return isFetch ? "fetch/" : "upload/";
};

const generateCloudinaryRawUrl = (src) => {
  const fixedSrc = addBasePathToUrl(src);
  const { isFetch, publicId } = extractPublicId(fixedSrc);

  const postfix = getCloudinaryMethodPostfix(isFetch);
  return `${CLOUDINARY_BASE}raw/${postfix}${publicId}`;
};

const getPrefix = (fileType) => {
  switch (fileType) {
    case CLOUDINARY_IMAGE_FILE_TYPE:
    case CLOUDINARY_GIF_FILE_TYPE:
      return CLOUDINARY_IMAGE_PREFIX;
    case CLOUDINARY_VIDEO_FILE_TYPE:
    case CLOUDINARY_VIDEO_THUMBNAIL_FILE_TYPE:
      return CLOUDINARY_VIDEO_PREFIX;
    default:
      return CLOUDINARY_IMAGE_PREFIX;
  }
};

const getImageTransformation = (assetQuality) => `f_auto,${assetQuality}`;
const getVideoTransformation = (assetQuality) => `${assetQuality},f_auto,cs_copy`; // reversed the order to clear cache with monday 2.0 video issue

const getTransformation = ({ fileType, assetQuality }) => {
  assetQuality = assetQuality || AUTO;
  switch (fileType) {
    case CLOUDINARY_IMAGE_FILE_TYPE:
      return getImageTransformation(assetQuality);
    case CLOUDINARY_VIDEO_FILE_TYPE:
      return getVideoTransformation(assetQuality);
    case CLOUDINARY_GIF_FILE_TYPE:
      return GIF_TO_VIDEO_AUTO_TRANSFORMATION;
    case CLOUDINARY_VIDEO_THUMBNAIL_FILE_TYPE:
      return VIDEO_THUMBNAIL_TRANSFORMATION;
    default:
      return getImageTransformation(assetQuality);
  }
};

const getPresets = (transformation, preset) => {
  if (!preset) return transformation;

  const transformations = [transformation];
  PresetKeysConvertionKey.forEach((key) => {
    if (preset[key]) {
      transformations.push(`${PresetKeysConvertion[key]}${preset[key]}`);
    }
  });
  return transformations.join(",");
};

const generateCloudinaryUrl = (fileType, publicId, isFetch, preset, options = {}) => {
  const { assetQuality } = options;
  const prefix = getPrefix(fileType);
  const postfix = getCloudinaryMethodPostfix(isFetch);
  const transformation = getTransformation({ fileType, assetQuality });
  const presets = getPresets(transformation, preset);
  const cloudinaryUrl = `${prefix}${postfix}${presets}/${publicId}`;
  return cloudinaryUrl;
};

const extractWidthTransformationIfNeeded = (props, cloudinaryTransformations) => {
  const { width } = props;
  if (!width) return cloudinaryTransformations;

  if (typeof width === "number") {
    cloudinaryTransformations.width = width;
  } else if (typeof width === "string" && width.indexOf("px") > -1) {
    cloudinaryTransformations.width = parseInt(width);
  }

  return cloudinaryTransformations;
};

const extractCloudinaryTransformations = (props = {}) => {
  let cloudinaryTransformations = {};
  cloudinaryTransformations = extractWidthTransformationIfNeeded(props, cloudinaryTransformations);
  return cloudinaryTransformations;
};

const generateCloudinaryImageUrl = (src, cloudinaryTransformations = null, options = {}) => {
  const fixedSrc = addBasePathToUrl(src);
  const { isFetch, publicId } = extractPublicId(fixedSrc);

  return generateCloudinaryUrl(CLOUDINARY_IMAGE_FILE_TYPE, publicId, isFetch, cloudinaryTransformations, options);
};

const getCloudinaryUrlSrc = (src) => {
  return isCloudinaryUrl(src) ? src : generateCloudinaryImageUrl(src);
};

const generateCloudinaryVideoThumbnail = (videoSrc) => {
  const fixedSrc = addBasePathToUrl(videoSrc);
  const fileWithImageExtension = replaceFileExtension(fixedSrc, JPG_FILE_EXTENSION);

  const { isFetch, publicId } = extractPublicId(fileWithImageExtension);

  return generateCloudinaryUrl(CLOUDINARY_VIDEO_THUMBNAIL_FILE_TYPE, publicId, isFetch, null);
};

const generateCloudinaryVideoUrl = (src, options = {}) => {
  const fixedSrc = addBasePathToUrl(src);
  const { isFetch, publicId } = extractPublicId(fixedSrc);

  const fileType = getFileExtension(src) === GIF_FILE_EXTENSION ? CLOUDINARY_GIF_FILE_TYPE : CLOUDINARY_VIDEO_FILE_TYPE;

  return generateCloudinaryUrl(fileType, publicId, isFetch, null, options);
};

const getPropValueFromCloudinaryUrl = (cloudinaryFullUrl) => {
  if (!cloudinaryFullUrl) return;
  const cloudinaryDecodedUrl = decodeURIComponent(cloudinaryFullUrl);

  const staticFolderPrefix = cloudinaryDecodedUrl.indexOf(CLOUDINARY_STATIC_FOLDER);
  if (staticFolderPrefix > -1) {
    const splitStr = cloudinaryDecodedUrl.substring(staticFolderPrefix + CLOUDINARY_STATIC_FOLDER.length);
    return `${CLOUDINARY_STATIC_FOLDER_NAME}${splitStr}`;
  }

  const featuredImagesFolderPrefix = cloudinaryDecodedUrl.indexOf(GENERATOR_FEATURED_IMAGES_FOLDER);
  if (featuredImagesFolderPrefix > -1) {
    return `/${cloudinaryDecodedUrl.substring(featuredImagesFolderPrefix)}`;
  }

  return cloudinaryFullUrl;
};

const getFolderValueFromCloudinaryUrl = (cloudinaryFullUrl) => {
  if (!cloudinaryFullUrl) return;
  const cloudinaryDecodedUrl = decodeURIComponent(cloudinaryFullUrl).replace("http:", "https:");
  const prefixUrl = `${CLOUDINARY_BASE}image/upload/`;
  const relativeUrl = cloudinaryDecodedUrl.indexOf(prefixUrl);
  if (relativeUrl > -1) {
    const relativeFolder = cloudinaryDecodedUrl.substring(prefixUrl.length, cloudinaryDecodedUrl.lastIndexOf("/"));
    return relativeFolder.substring(relativeFolder.indexOf("/") + 1, relativeFolder.length); // remove the v[number] folder path
  }

  return null;
};

const getCloudinaryMediaLibraryAuthenticationAccess = async () => {
  const { get } = require("../../secrets-service");
  const { api_key: CLOUDINARY_API_KEY, api_secret: CLOUDINARY_API_SECRET } = await get("cloudinary");

  const timeStamp = Math.round(new Date().getTime() / 1000);
  const userName = CLOUDINARY_MEDIA_LIBRARY_USER_NAME;
  const authString = `cloud_name=${CLOUDINARY_CLOUD_NAME}&timestamp=${timeStamp}&username=${userName}${CLOUDINARY_API_SECRET}`;
  const signature = SHA256(authString).toString();
  const apiKey = CLOUDINARY_API_KEY;

  return { timeStamp, signature, userName, apiKey };
};

const getMediaLibraryCreationConfig = (cloudinaryAuthentication) => {
  return {
    cloud_name: CLOUDINARY_CLOUD_NAME,
    api_key: cloudinaryAuthentication?.apiKey,
    username: cloudinaryAuthentication?.userName,
    insert_caption: "Select",
    timestamp: cloudinaryAuthentication?.timeStamp,
    signature: cloudinaryAuthentication?.signature,
    multiple: false
  };
};

const isCloudinaryUrl = (url) => {
  return url?.indexOf(CLOUDINARY_BASE) === 0 || url?.indexOf(CLOUDINARY_UNSECURE_BASE) === 0;
};

const getColorTransformation = (color) => {
  if (!color) return null;

  // if color doesn't start with a pound sign (although should) -
  // return an empty string that would not affect the Cloudinary url
  if (color.charAt(0) !== "#") {
    return null;
  }
  // remove pound sound from color
  const colorWithNoPoundSign = color.substring(1);
  return `e_colorize,co_rgb:${colorWithNoPoundSign}/`;
};

/* DON'T USE THIS FUNCTION
 * It's currently used for a specific use case, and will be fixed later so it can be used by any component
 * */
const getUrlWithColor = ({ url, color }) => {
  const cloudinaryDecodedUrl = decodeURIComponent(url);
  const colorString = getColorTransformation(color);
  // if failed to create the color transformation
  if (!colorString) {
    return url;
  }

  const insertPosition = cloudinaryDecodedUrl.indexOf(GENERATOR_FEATURED_IMAGES_FOLDER);
  const urlWithColor = [
    cloudinaryDecodedUrl.slice(0, insertPosition),
    colorString,
    cloudinaryDecodedUrl.slice(insertPosition)
  ].join("");
  return urlWithColor;
};

module.exports = {
  CLOUDINARY_CLOUD_NAME,
  LOW_RES_IMAGE_QUALITY_LEVEL,
  IMAGE_DEFAULT_BLUR,
  CLOUDINARY_IMAGE_FILE_TYPE,
  PresetTransformations,
  extractPublicId,
  generateCloudinaryUrl,
  generateCloudinaryImageUrl,
  generateCloudinaryVideoUrl,
  generateCloudinaryRawUrl,
  generateCloudinaryVideoThumbnail,
  extractCloudinaryTransformations,
  CLOUDINARY_STATIC_FOLDER,
  CLOUDINARY_BASE_VIDEO_URL,
  CLOUDINARY_IMAGE_UPLOADS_BASE_FOLDER,
  getPropValueFromCloudinaryUrl,
  getFolderValueFromCloudinaryUrl,
  getCloudinaryMediaLibraryAuthenticationAccess,
  CLOUDINARY_MEDIA_LIBRARY_SCRIPT,
  CLOUDINARY_VIDEO_RESOURCE,
  CLOUDINARY_IMAGE_RESOURCE,
  getMediaLibraryCreationConfig,
  isCloudinaryUrl,
  CLOUDINARY_IMAGE_PREFIX,
  FALLBACK_DEFAULT_IMAGE_TRANSFORMATION_PARAM,
  getCloudinaryUrlSrc,
  getUrlWithColor
};
