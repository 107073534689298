const PRODUCTION_DOMAIN = "monday.com";

export const getPlatformLinkByEnvironment = (productionLink) => {
  try {
    if (process.env.NEXT_PUBLIC_AUTH_PLATFORM_DOMAIN === PRODUCTION_DOMAIN) return productionLink;

    return productionLink.replace(PRODUCTION_DOMAIN, process.env.NEXT_PUBLIC_AUTH_PLATFORM_DOMAIN);
  } catch {
    return productionLink;
  }
};
