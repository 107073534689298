export const TIERS = {
  FREE: "free",
  BASIC: "basic",
  STANDARD: "standard",
  PRO: "pro",
  ENTERPRISE: "enterprise"
};

export const PERIODS = {
  yearly: "yearly",
  monthly: "monthly"
};

export const PLATFORM_PERIODS = {
  [PERIODS.yearly]: "YearlyPlan",
  [PERIODS.monthly]: "MonthlyPlan"
};

export const PERIOD_TEXTS = {
  [PERIODS.yearly]: {
    billed: "pricingPage.periods.yearly.billed",
    displayName: "pricingPage.periods.yearly.displayName"
  },
  [PERIODS.monthly]: {
    billed: "pricingPage.periods.monthly.billed",
    displayName: "pricingPage.periods.monthly.displayName"
  }
};

export const FEATURES_IDS = {
  STORAGE: "STORAGE"
};

export const PLAN_INFO_SEGMENT_NAME = "PlanInfo";

export const MAX_TEAM_SIZE = "200+";

export const ORDERED_PERIOD_NAMES = [PERIODS.yearly, PERIODS.monthly];

export const STANDARD_DASHBOARDS_AMOUNT = 3;
export const PRO_DASHBOARDS_AMOUNT = 10;
export const NUM_DEFAULT_DISPLAYED_PLANS = 4;
export const FREE_TIER_2021_SEATS_LIMIT = 2;
export const FREE_TIER_2022_SEATS_LIMIT = 5;

export const SUPPORTED_CURRENCIES = [
  {
    name: "usd",
    currencySign: "$",
    currencyShortText: "USD",
    shouldDisplayText: true
  },
  {
    name: "euro",
    currencySign: "€",
    currencyShortText: "EUR",
    shouldDisplayText: false
  },
  {
    name: "cad",
    currencySign: "$",
    currencyShortText: "CAD",
    shouldDisplayText: true
  },
  {
    name: "gbp",
    currencySign: "£",
    currencyShortText: "GBP",
    shouldDisplayText: false
  },
  {
    name: "aud",
    currencySign: "$",
    currencyShortText: "AUD",
    shouldDisplayText: true
  },
  {
    name: "mxn",
    currencySign: "$",
    currencyShortText: "MXN",
    shouldDisplayText: true
  },
  {
    name: "brl",
    currencySign: "R$",
    currencyShortText: "BRL",
    shouldDisplayText: false
  },
  {
    name: "inr",
    currencySign: "₹",
    currencyShortText: "INR",
    shouldDisplayText: false
  },
  {
    name: "jpy",
    currencySign: "¥",
    currencyShortText: "JPY",
    shouldDisplayText: false
  }
];
