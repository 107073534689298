import {
  RIVERSTONE_COLOR_NAME,
  DODGER_BLUE_COLOR_NAME,
  SPACE_BLUE_COLOR_NAME,
  WHITE_COLOR_NAME,
  IRIS_COLOR_NAME,
  PROJECTS_BLUE_COLOR_NAME,
  PROJECTS_ORANGE_COLOR_NAME,
  MARKETING_RED_COLOR_NAME,
  CRM_GREEN_COLOR_NAME,
  DEV_ORANGE_COLOR_NAME,
  DEV_GREEN_COLOR_NAME,
  WORK_OS_IRIS_COLOR_NAME,
  LIGHT_PROJECTS_BLUE_COLOR_NAME,
  LIGHT_PROJECTS_ORANGE_COLOR_NAME,
  LIGHT_MARKETING_RED_COLOR_NAME,
  LIGHT_CRM_GREEN_COLOR_NAME,
  LIGHT_DEV_ORANGE_COLOR_NAME,
  LIGHT_DEV_GREEN_COLOR_NAME,
  LIGHT_WORK_OS_IRIS_COLOR_NAME,
  DARK_PROJECTS_BLUE_COLOR_NAME,
  DARK_PROJECTS_ORANGE_COLOR_NAME,
  DARK_MARKETING_RED_COLOR_NAME,
  DARK_CRM_GREEN_COLOR_NAME,
  DARK_DEV_ORANGE_COLOR_NAME,
  DARK_DEV_GREEN_COLOR_NAME,
  DARK_WORK_OS_IRIS_COLOR_NAME,
  BACKGROUND_GRAY_COLOR_NAME,
  BRAND_BLACK_TEXT_COLOR,
  SPACE_BLUE_GRADIANT_COLOR_NAME,
  SPACE_BLUE_VIDEO_ONLY_COLOR_NAME,
  SPACE_BLUE_VIDEO_ONLY_DESKTOP_COLOR_NAME,
  GRADIANT_WORKOS_IRIS_COLOR_NAME,
  WORKFORMS_RED_COLOR_NAME,
  LIGHT_BLUE_VIDEO_BACKGROUND_COLOR_NAME,
  GRADIANT_BRAND_GRAY_COLOR_NAME,
  PEACH_COLOR_NAME,
  TEAL_COLOR_NAME,
  GRADIANT_WORKOS_IRIS_HOVER_COLOR_NAME,
  GHOST_WHITE_COLOR_NAME,
  ELEVATE_BANNER_GRADIANT_COLOR_NAME,
  ELEVATE_BUTTON_GRADIANT_COLOR_NAME,
  SIGN_UP_WITH_PRODUCTS_COLOR_NAME,
  REACT_MODAL_BACKGROUND_COLOR_NAME,
  BRAND_WORK_MANAGEMENT_PRIMARY_COLOR,
  BRAND_WORK_MANAGEMENT_PRIMARY_COLOR_NAME,
  BRAND_WORK_MANAGEMENT_LIGHTER_TINT_01,
  BRAND_AND_WORK_MANAGEMENT_LIGHT_TINT_02,
  BRAND_AND_WORK_MANAGEMENT_DARK_TINT_01,
  BRAND_AND_WORK_MANAGEMENT_DARKER_TINT_02,
  BRAND_PRIMARY_YELLOW,
  BRAND_YELLOW_DARK_TINT_01,
  BRAND_YELLOW_DARKER_TINT_02,
  BRAND_PRIMARY_RED,
  BRAND_RED_DARK_TINT_01,
  BRAND_RED_DARKER_TINT_02,
  BRAND_PRIMARY_GREEN,
  BRAND_GREEN_DARK_TINT_01,
  BRAND_GREEN_DARKER_TINT_02,
  BRAND_DARK_COLOR,
  BRAND_BLACK_BACKGROUND_COLOR,
  CRM_PRIMARY_COLOR,
  CRM_LIGHTER_TINT_01,
  CRM_LIGHT_TINT_02,
  CRM_DARK_TINT_01,
  CRM_DARK_TINT_01_COLOR_NAME,
  CRM_DARKER_TINT_02,
  DEV_PRIMARY_COLOR,
  DEV_LIGHTER_TINT_01,
  DEV_LIGHT_TINT_02,
  DEV_DARK_TINT_01,
  DEV_DARKER_TINT_02,
  MARKETER_PRIMARY_COLOR,
  MARKETER_LIGHTER_TINT_01,
  MARKETER_LIGHT_TINT_02,
  MARKETER_DARK_TINT_01,
  MARKETER_DARKER_TINT_02,
  PROJECTS_PRIMARY_COLOR,
  PROJECTS_LIGHTER_TINT_01,
  PROJECTS_LIGHT_TINT_02,
  PROJECTS_DARK_TINT_01,
  PROJECTS_DARKER_TINT_02,
  PROJECTS_DARKEST_TINT_03,
  UI_BORDER_LIGHT_COLOR_NAME,
  BRAND_GREEN_DARK_TINT_01_COLOR_NAME
} from "./color-consts";

const colors = {
  "signup-pink": "#ff0476",
  "signup-pink-hover": "#b70053",
  [WHITE_COLOR_NAME]: "#ffffff",
  White: "#ffffff",
  "white-smoke": "#f7f7f7",
  [BRAND_BLACK_TEXT_COLOR]: "#333333",
  "light-blue": "#00a9ff",
  [LIGHT_BLUE_VIDEO_BACKGROUND_COLOR_NAME]: "#dff0ff",
  "light-blue-hover": "#0087CC",
  "light-grey": "#f5f5f5",
  malachite: "#00d068",
  "malachite-darken": "#1ea95e",
  "malachite-hover": "#1ea95e",
  "malachite-hover-darken": "#167a45",
  "clickable-link": "#00a1e5",
  error: "red",
  grey: "#cccccc",
  pinterest: "#dd4b39",
  "pinterest-hover": "#c23321",
  "youtube-hover": "#a11918",
  "twitter-hover": "#0c85d0",
  "linkedin-hover": "#005582",
  "menu-mobile-green": "#40c870",
  transparent: "transparent",

  "brand-red": "#FB275D",
  "brand-yellow": "#FFCC00",
  "brand-green": "#00CC6F",
  "brand-light-green": "#EEFCF5",
  "brand-purple": "#A358DF",
  "brand-pink": "#ff0476",
  "brand-light-blue": "#00CFFF",
  "brand-dark-blue": "#595AD4",
  "brand-blue": "#009AFF",

  "brand-red-hover": "#c12c52",
  "brand-green-hover": "#099655",
  "brand-yellow-hover": "#b79407",
  "brand-purple-hover": "#9031DC",
  "brand-iris": "#595ad4",
  "brand-iris-hover": "#3335c7",
  "brand-blue-hover": "#0085DD",
  "brand-indigo": "#5559df",
  "blue-hover": "#0584D7",

  "plan-red": "#ff3e59",
  "plan-yellow": "#fab715",
  "plan-green": "#40c870",
  "plan-purple": "#9d5de0",
  snow: "#f9f9f9",

  silver: "#c4c4c4",
  "billing-period-blue": "#00a1e6",
  "pricing-border": "#efefef",
  "pricing-background": "#f9f9f9",
  "modal-signup-title-yellow": "#fad200",
  "modal-signup-close-grey": "#999",
  "dark-marble": "#f1f1f1",
  "brand-gray": "#808080",
  "brand-enterprise-dark": "#2b2c5d",
  "community-homepage-bg": "#fff",
  "dark-blue": "#1d1e3d",
  "basic-blue": "#0ca5e7",
  "developers-border-grey": "#e3e3e3",
  "developers-light-green": "#3ec28d",
  "developers-description-grey": "#b0b0b0",
  "developers-dark-blue": "#292f4c",
  "developers-light-purple": "#abb7ff",
  "developers-apricot": "#ffcdab",

  jaco: "#9699a6",
  "mint-green": "#25DBC5",
  "border-gray": "#e6e9ef",
  "bold-blue": "#15BBE4",
  wolf: "#c5c7d0",
  "background-blue": "#F5FDFF",
  "background-bold-blue": "#E3F9FF",

  // new colors =========================================================
  purple: "#a358df",
  blue: "#0085ff",
  [IRIS_COLOR_NAME]: "#595ad4",
  "iris-hover": "#3C3ED6",
  pink: "#ff0476",
  "pink-hover": "#DC0064",
  royal: "#597bfc",
  "royal-hover": "#305BFF",
  green: "#00ca72",
  "green-hover": "#01A55E",
  yellow: "#ffcb00",
  orange: "#fdab3d",
  red: "#fb275d",
  "surface-blue": "#1c1f3b",
  mud: "#323338",
  [RIVERSTONE_COLOR_NAME]: "#F5F6F8",
  "highlight-blue": "#e5f4ff",
  noam: "#3606ad",
  tuna: "#383B45",
  "shuttle-gray": "#666972",
  [DODGER_BLUE_COLOR_NAME]: "#4353FF",
  "dodger-blue-hover": "#2F3AC5",
  "space-blue-hover": "#02032C",
  "brand-turqoise": "#00CFF4",
  "gradiant-gray": "linear-gradient(180deg, #F5F6F8 0%, rgba(245, 246, 248, 0) 67.54%)",
  [GRADIANT_BRAND_GRAY_COLOR_NAME]: "linear-gradient(180deg, #F0F3FF 0%, rgba(245, 246, 248, 0) 67.54%)",
  "gradiant-pink": "linear-gradient(180deg, #FFFAFE 0%, rgba(245, 246, 248, 0) 70%)",
  [GRADIANT_WORKOS_IRIS_COLOR_NAME]: "linear-gradient(90deg, #5034FF 25.69%, #B4B4FF 100%)",
  [GRADIANT_WORKOS_IRIS_HOVER_COLOR_NAME]: "linear-gradient(90deg, #432cd5 25.69%, #9797d5 100%)",
  [SIGN_UP_WITH_PRODUCTS_COLOR_NAME]: "linear-gradient(183deg, #0F1048 0 49.7%, transparent 50% 100%)",
  [SPACE_BLUE_GRADIANT_COLOR_NAME]: "linear-gradient(180deg, #000133 0%, #0F1048 100%)",
  [SPACE_BLUE_VIDEO_ONLY_COLOR_NAME]: "#0F1045",
  [SPACE_BLUE_VIDEO_ONLY_DESKTOP_COLOR_NAME]: "#0E0E53",
  "gradiant-elevate": "radial-gradient(146.02% 323.89% at 132.42% -98.86%, #7071FF 39.4%, #0B1133 100%)",
  "text-gray": "#535768",
  enterprise: "#2b2c5d",
  asphalt: "#676879",
  gray: "#AAAAAA",
  "wolf-gray": "#C5C7D0",
  midnightblue: "#27285a",
  "midnightblue-hover": "#191a4f",
  gainsboro: "#DCDFEC",
  [GHOST_WHITE_COLOR_NAME]: "#F6F7FB",
  // new tags colors
  "green-2": "#00c875",
  "dark-green": "#037f4c",
  "bright-green": "#9cd326",
  saladish: "#cab641",
  "dark-orange": "#ff642e",
  "red-v2": "#e2445c",
  "dark-red": "#ba3254",
  "sofia-pink": "#ff158a",
  lipstick: "#ff5ac4",
  "dark-purple": "#784bd1",
  "purple-v2": "#a25ddc",
  "dark-blue-v2": "#0086c0",
  "light-blue-v2": "#579bfc",
  "chill-blue": "#66ccff",
  brown: "#7f5347",
  sunset: "#ff7575",
  bubble: "#faa1f1",
  peach: "#ffadad",
  berry: "#7e3b8a",
  winter: "#9aadbd",
  river: "#68a1bd",
  navy: "#225091",
  aquamarine: "#4eccc6",
  indigo: "#5559df",
  "dark-indigo": "#401694",
  "done-green": "#00ca72",
  "accessible-green": "#00a25b",

  "platform-red": "#f74875",
  lime: "#E5F986",
  [PEACH_COLOR_NAME]: "#FFD9BD",
  [TEAL_COLOR_NAME]: "#0E508A",
  [REACT_MODAL_BACKGROUND_COLOR_NAME]: "#000128",

  // Google Connect colors
  "white-iris": "#ffffff",

  // Product new colors:

  // TODO: currently platform is using a lighter colors (bottom 3). We need to fix this after Branding decisions are final
  "product-core": "#8f8fff",
  "product-marketing": "#FF5BA0",
  "product-project-management": "#65B5FF",

  /** New branding colors - Start */

  // Base version
  [WORK_OS_IRIS_COLOR_NAME]: "#6161FF",
  [MARKETING_RED_COLOR_NAME]: "#F04095",
  [PROJECTS_ORANGE_COLOR_NAME]: "#FF9900",
  [CRM_GREEN_COLOR_NAME]: "#00D2D2",
  [DEV_GREEN_COLOR_NAME]: "#00C875",

  [WORKFORMS_RED_COLOR_NAME]: "#C21E71",

  // Light version
  [LIGHT_WORK_OS_IRIS_COLOR_NAME]: "#B4B4FF",
  [LIGHT_MARKETING_RED_COLOR_NAME]: "#F8C3DD",
  [LIGHT_PROJECTS_ORANGE_COLOR_NAME]: "#FFC960",
  [LIGHT_CRM_GREEN_COLOR_NAME]: "#D3F4F4",
  [LIGHT_DEV_GREEN_COLOR_NAME]: "#CAF4E2",

  // Dark version
  [DARK_WORK_OS_IRIS_COLOR_NAME]: "#5034FF",
  [DARK_MARKETING_RED_COLOR_NAME]: "#CA0C6B",
  [DARK_PROJECTS_ORANGE_COLOR_NAME]: "#F86700",
  [DARK_CRM_GREEN_COLOR_NAME]: "#007474",
  [DARK_DEV_GREEN_COLOR_NAME]: "#037F4C",

  // New branding colors:
  [SPACE_BLUE_COLOR_NAME]: "#0F1048",
  [BACKGROUND_GRAY_COLOR_NAME]: "#F0F3FF",

  // Elevate colors:
  [ELEVATE_BANNER_GRADIANT_COLOR_NAME]:
    "linear-gradient(269.84deg, #7B7AE1 -4.01%, #060A3B 23.71%, #BD1168 50.42%, #50CEE3 92.75%)",
  [ELEVATE_BUTTON_GRADIANT_COLOR_NAME]: "linear-gradient(265.73deg, #FC9618 0.75%, #FFCC00 101.64%)",
  /** New branding colors - End */

  // New brand colors
  [BRAND_WORK_MANAGEMENT_PRIMARY_COLOR]: "#6161FF",
  [BRAND_WORK_MANAGEMENT_PRIMARY_COLOR_NAME]: "#6161FF",
  [BRAND_WORK_MANAGEMENT_LIGHTER_TINT_01]: "#ECEFF8",
  [BRAND_AND_WORK_MANAGEMENT_LIGHT_TINT_02]: "#CFCFF9",
  [BRAND_AND_WORK_MANAGEMENT_DARK_TINT_01]: "#452AEA",
  [BRAND_AND_WORK_MANAGEMENT_DARKER_TINT_02]: "#1F0F83",
  [BRAND_PRIMARY_YELLOW]: "#FFCC00",
  [BRAND_YELLOW_DARK_TINT_01]: "#CAA100",
  [BRAND_YELLOW_DARKER_TINT_02]: "#A58000",
  [BRAND_PRIMARY_RED]: "#FB275D",
  [BRAND_RED_DARK_TINT_01]: "#B1123B",
  [BRAND_RED_DARKER_TINT_02]: "#870023",
  [BRAND_PRIMARY_GREEN]: "#00CA72",
  [BRAND_GREEN_DARK_TINT_01]: "#00854D",
  [BRAND_GREEN_DARK_TINT_01_COLOR_NAME]: "#00854D",
  [BRAND_GREEN_DARKER_TINT_02]: "#025231",
  [BRAND_DARK_COLOR]: "#181B34",
  [BRAND_BLACK_BACKGROUND_COLOR]: "#000000",
  [CRM_PRIMARY_COLOR]: "#00D2D2",
  [CRM_LIGHTER_TINT_01]: "#E1EFF2",
  [CRM_LIGHT_TINT_02]: "#BEE3E8",
  [CRM_DARK_TINT_01]: "#00889B",
  [CRM_DARK_TINT_01_COLOR_NAME]: "#00889B",
  [CRM_DARKER_TINT_02]: "#006278",
  [DEV_PRIMARY_COLOR]: "#00C875",
  [DEV_LIGHTER_TINT_01]: "#E2F0E7",
  [DEV_LIGHT_TINT_02]: "#C6E9D5",
  [DEV_DARK_TINT_01]: "#00854D",
  [DEV_DARKER_TINT_02]: "#025231",
  [MARKETER_PRIMARY_COLOR]: "#E21277",
  [MARKETER_LIGHTER_TINT_01]: "#F5EAF0",
  [MARKETER_LIGHT_TINT_02]: "#F8D4E6",
  [MARKETER_DARK_TINT_01]: "#BB0C61",
  [MARKETER_DARKER_TINT_02]: "#880044",
  [PROJECTS_PRIMARY_COLOR]: "#FF9900",
  [PROJECTS_LIGHTER_TINT_01]: "#F9EDE8",
  [PROJECTS_LIGHT_TINT_02]: "#F8DCCF",
  [PROJECTS_DARK_TINT_01]: "#F86700",
  [PROJECTS_DARKER_TINT_02]: "#D14900",
  [PROJECTS_DARKEST_TINT_03]: "#963703",

  // Platform colors
  [UI_BORDER_LIGHT_COLOR_NAME]: "#C3C6D4"
};

// DEPRECATED - FALLING BACK TO NEW PRODUCT COLORS
colors[DEV_ORANGE_COLOR_NAME] = colors[DEV_GREEN_COLOR_NAME];
colors[LIGHT_DEV_ORANGE_COLOR_NAME] = colors[DEV_GREEN_COLOR_NAME];
colors[DARK_DEV_ORANGE_COLOR_NAME] = colors[DEV_GREEN_COLOR_NAME];
colors[PROJECTS_BLUE_COLOR_NAME] = colors[PROJECTS_ORANGE_COLOR_NAME];
colors[LIGHT_PROJECTS_BLUE_COLOR_NAME] = colors[PROJECTS_ORANGE_COLOR_NAME];
colors[DARK_PROJECTS_BLUE_COLOR_NAME] = colors[PROJECTS_ORANGE_COLOR_NAME];

export default colors;
