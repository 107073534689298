const { getLogger } = require("@mondaydotcomorg/monday-logger");
const {
  AB_TEST_EXACT_EVENT_NAME,
  REFERER_HEADER,
  CLOUDFRONT_MOBILE_HEADER,
  CLOUDFRONT_TABLET_HEADER,
  CLOUDFRONT_DESKTOP_HEADER,
  CLOUDFRONT_VIEWER_COUNTRY_HEADER
} = require("../../constants/headers");
const { InternalServerError } = require("../extensions/error-extensions");
const { ERROR_LOG_LEVEL, INFO_LOG_LEVEL, WARNING_LOG_LEVEL, DEBUG_LOG_LEVEL } = require("./consts");
const {
  REQ_PROP_PATH,
  REQ_PROP_URL,
  REQ_PROP_REFERER,
  REQ_PROP_CLOUDFRONT_MOBILE_HEADER,
  REQ_PROP_CLOUDFRONT_TABLET_HEADER,
  REQ_PROP_CLOUDFRONT_DESKTOP_HEADER,
  REQ_PROP_CLOUDFRONT_VIEWER_COUNTRY
} = require("./consts");
const { RES_PROP_AB_TEST_EXACT_EVENT_NAME } = require("./consts");

const getReqParams = (req) => {
  if (!req) return {};
  return {
    [REQ_PROP_PATH]: req.path,
    [REQ_PROP_URL]: req.url,
    [REQ_PROP_REFERER]: req.headers[REFERER_HEADER],
    [REQ_PROP_CLOUDFRONT_MOBILE_HEADER]: req.headers[CLOUDFRONT_MOBILE_HEADER],
    [REQ_PROP_CLOUDFRONT_TABLET_HEADER]: req.headers[CLOUDFRONT_TABLET_HEADER],
    [REQ_PROP_CLOUDFRONT_DESKTOP_HEADER]: req.headers[CLOUDFRONT_DESKTOP_HEADER],
    [REQ_PROP_CLOUDFRONT_VIEWER_COUNTRY]: req.headers[CLOUDFRONT_VIEWER_COUNTRY_HEADER]
  };
};

const getResParams = (res) => {
  if (!res) return {};
  return {
    [RES_PROP_AB_TEST_EXACT_EVENT_NAME]: res.getHeader(AB_TEST_EXACT_EVENT_NAME)
  };
};

const log = (logLevel, { message, req, res, params }) => {
  try {
    const logger = getLogger();
    const loggerParams = {
      ...getReqParams(req),
      ...getResParams(res),
      ...params
    };

    switch (logLevel) {
      case INFO_LOG_LEVEL:
        logger.info({ ...loggerParams }, message);
        break;
      case WARNING_LOG_LEVEL:
        logger.warn({ ...loggerParams }, message);
        break;
      case ERROR_LOG_LEVEL:
        logger.error({ ...loggerParams }, message);
        break;
      case DEBUG_LOG_LEVEL:
      default:
        logger.debug({ ...loggerParams }, message);
        break;
    }
  } catch (ex) {
    console.log("Logging failed.");
  }
};

const logInfo = (message, paramaters = {}) => {
  const { req, res, ...params } = paramaters;
  log(INFO_LOG_LEVEL, { message, req, res, params });
};

const logError = (message, paramaters = {}) => {
  const { req, res, ...params } = paramaters;
  log(ERROR_LOG_LEVEL, { message, req, res, params });
};

const logWarning = (message, paramaters = {}) => {
  const { req, res, ...params } = paramaters;
  log(WARNING_LOG_LEVEL, { message, req, res, params });
};

const logDebug = (message, paramaters = {}) => {
  const { req, res, ...params } = paramaters;
  log(DEBUG_LOG_LEVEL, { message, req, res, params });
};

const logException = ({ externalMessage, e, req, res, extraLogParams }) => {
  try {
    let logMessage = "Unhandled exception";
    let logParams;
    if (e instanceof InternalServerError) {
      const { message, params } = e;
      logMessage = "Internal server error";
      logParams = { ...params, exceptionMessage: message };
    } else {
      logParams = { exceptionMessage: e && e.message != null ? e.message : e };
    }
    logParams = {
      ...logParams,
      ...extraLogParams,
      externalMessage
    };
    log(ERROR_LOG_LEVEL, { message: logMessage, params: logParams, req, res });
  } catch (ex) {
    console.log("Logging exception failed.");
  }
};

module.exports = {
  log,
  logException,
  logInfo,
  logWarning,
  logError,
  logDebug
};
