import {
  CRM_MINI_SITE_LINK,
  CRM_PRODUCT_SIGN_UP_PAGE_LINK,
  DEV_PRODUCT_SIGN_UP_PAGE_LINK,
  MARKETER_PRODUCT_SIGN_UP_PAGE_LINK,
  MARKETING_MANAGEMENT_MINI_SITE_LINK,
  NEW_SOFT_SIGNUP_LINK,
  PROJECT_MANAGEMENT_MINI_SITE_LINK,
  PROJECTS_PRODUCT_SIGN_UP_PAGE_LINK,
  SOFTWARE_MINI_SITE_LINK
} from "constants/links";
import * as Sentry from "@sentry/nextjs";
import MondayCRMSquareIcon from "static/svg/header/monday_crm_square.svg";
import MondayMarketerSquareIcon from "static/svg/header/monday_marketer_square.svg";
import MondayProjectsSquareIcon from "static/svg/header/monday_projects_square.svg";
import MondayDevSquareIcon from "static/svg/header/monday_dev_square.svg";
import { CRM, DESIGN_AND_CREATIVE, MARKETING, SOFTWARE } from "constants/clusters.js";
import { ENGLISH_LOCALE_ID } from "constants/localization";
import {
  DARK_CRM_GREEN_COLOR_NAME,
  DARK_DEV_GREEN_COLOR_NAME,
  DARK_MARKETING_RED_COLOR_NAME,
  DARK_PROJECTS_ORANGE_COLOR_NAME,
  WORK_OS_IRIS_COLOR_NAME
} from "../styles/color-consts";
import {
  CRM_GREEN_THEME_ID,
  MARKETING_RED_THEME_ID,
  PROJECTS_ORANGE_THEME_ID,
  DEV_GREEN_THEME_ID,
  WORKOS_IRIS_THEME_ID
} from "/segments/segments-service";

export const CRM_PRODUCT_TYPE = "CRM product";
export const MARKETER_PRODUCT_TYPE = "Marketer product";
export const PROJECTS_PRODUCT_TYPE = "Projects product";
export const DEV_PRODUCT_TYPE = "Dev product";
export const WORK_MANAGEMENT_PRODUCT_TYPE = "Work management product";

export const CRM_PRODUCT_ID = "crm";
export const MARKETER_PRODUCT_ID = "marketer";
export const PROJECTS_PRODUCT_ID = "projects";
export const DEV_PRODUCT_ID = "dev";
export const WORK_MANAGEMENT_PRODUCT_ID = "work-management";

const PLATFORM_WORK_MANAGEMENT_PRODUCT_ID = "core";
const PLATFORM_MARKETER_PRODUCT_ID = "marketing";
const PLATFORM_CRM_PRODUCT_ID = "crm";
const PLATFORM_DEV_PRODUCT_ID = "software";
const PLATFORM_PROJECTS_PRODUCT_ID = "project_management";

export const PRODUCT_TYPES = [
  CRM_PRODUCT_TYPE,
  MARKETER_PRODUCT_TYPE,
  PROJECTS_PRODUCT_TYPE,
  DEV_PRODUCT_TYPE,
  WORK_MANAGEMENT_PRODUCT_TYPE
];

export const PRODUCTS_THEMES = {
  [CRM_PRODUCT_TYPE]: CRM_GREEN_THEME_ID,
  [MARKETER_PRODUCT_TYPE]: MARKETING_RED_THEME_ID,
  [PROJECTS_PRODUCT_TYPE]: PROJECTS_ORANGE_THEME_ID,
  [DEV_PRODUCT_TYPE]: DEV_GREEN_THEME_ID,
  [WORK_MANAGEMENT_PRODUCT_TYPE]: WORKOS_IRIS_THEME_ID
};

export const PRODUCTS_ID_TO_THEME = {
  [CRM_PRODUCT_ID]: DARK_CRM_GREEN_COLOR_NAME,
  [MARKETER_PRODUCT_ID]: DARK_MARKETING_RED_COLOR_NAME,
  [PROJECTS_PRODUCT_ID]: DARK_PROJECTS_ORANGE_COLOR_NAME,
  [DEV_PRODUCT_ID]: DARK_DEV_GREEN_COLOR_NAME,
  [WORK_MANAGEMENT_PRODUCT_ID]: WORK_OS_IRIS_COLOR_NAME
};

export const PRODUCT_OPTIONS = [
  { value: CRM_PRODUCT_ID, text: CRM_PRODUCT_TYPE },
  { value: MARKETER_PRODUCT_ID, text: MARKETER_PRODUCT_TYPE },
  { value: PROJECTS_PRODUCT_ID, text: PROJECTS_PRODUCT_TYPE },
  { value: DEV_PRODUCT_ID, text: DEV_PRODUCT_TYPE },
  { value: WORK_MANAGEMENT_PRODUCT_ID, text: WORK_MANAGEMENT_PRODUCT_TYPE }
];

export const PRODUCT_IDS = [
  CRM_PRODUCT_ID,
  MARKETER_PRODUCT_ID,
  PROJECTS_PRODUCT_ID,
  DEV_PRODUCT_ID,
  WORK_MANAGEMENT_PRODUCT_ID
];

const PRODUCT_SIGNUP_URLS = {
  [CRM_PRODUCT_ID]: CRM_PRODUCT_SIGN_UP_PAGE_LINK,
  [MARKETER_PRODUCT_ID]: MARKETER_PRODUCT_SIGN_UP_PAGE_LINK,
  [DEV_PRODUCT_ID]: DEV_PRODUCT_SIGN_UP_PAGE_LINK,
  [PROJECTS_PRODUCT_ID]: PROJECTS_PRODUCT_SIGN_UP_PAGE_LINK,
  [WORK_MANAGEMENT_PRODUCT_ID]: NEW_SOFT_SIGNUP_LINK // Work management links to the regular signup
};

const PLATFORM_PRODUCT_IDS = {
  [CRM_PRODUCT_ID]: PLATFORM_CRM_PRODUCT_ID,
  [MARKETER_PRODUCT_ID]: PLATFORM_MARKETER_PRODUCT_ID,
  [DEV_PRODUCT_ID]: PLATFORM_DEV_PRODUCT_ID,
  [PROJECTS_PRODUCT_ID]: PLATFORM_PROJECTS_PRODUCT_ID,
  [WORK_MANAGEMENT_PRODUCT_ID]: PLATFORM_WORK_MANAGEMENT_PRODUCT_ID
};

export const clusterIdToProductIdMap = {
  [DESIGN_AND_CREATIVE]: MARKETER_PRODUCT_ID,
  [MARKETING]: MARKETER_PRODUCT_ID,
  [CRM]: CRM_PRODUCT_ID,
  [SOFTWARE]: DEV_PRODUCT_ID
};

export const productBannerProps = {
  [MARKETER_PRODUCT_ID]: {
    productName: "marketing",
    buttonColor: DARK_MARKETING_RED_COLOR_NAME,
    url: MARKETING_MANAGEMENT_MINI_SITE_LINK,
    icon: MondayMarketerSquareIcon
  },
  [CRM_PRODUCT_ID]: {
    productName: "crm",
    buttonColor: DARK_CRM_GREEN_COLOR_NAME,
    url: CRM_MINI_SITE_LINK,
    icon: MondayCRMSquareIcon
  },
  [PROJECTS_PRODUCT_ID]: {
    productName: "projectManagement",
    buttonColor: DARK_PROJECTS_ORANGE_COLOR_NAME,
    url: PROJECT_MANAGEMENT_MINI_SITE_LINK,
    icon: MondayProjectsSquareIcon
  },
  [DEV_PRODUCT_ID]: {
    productName: "software",
    buttonColor: DARK_DEV_GREEN_COLOR_NAME,
    url: SOFTWARE_MINI_SITE_LINK,
    icon: MondayDevSquareIcon
  }
};

export const getProductSignupURL = (productValue) => {
  const productSignupURL = PRODUCT_SIGNUP_URLS[productValue];
  if (!productSignupURL) {
    Sentry.captureMessage(`Product signup URL not found for product value: ${productValue}`);
    return NEW_SOFT_SIGNUP_LINK;
  }

  return productSignupURL;
};

// platform uses cluster id as the product name - when we send data to platform it's important to sync in their language
// Synced to platform code in app/consts/product_types.rb - Danger rule for sync added in the platform code
export const getPlatformProductID = (productValue) => {
  const platformProductId = PLATFORM_PRODUCT_IDS[productValue];
  if (!platformProductId) {
    Sentry.captureMessage(`Platform product ID not found for product value: ${productValue}`);
  }
  return platformProductId;
};

export const shouldGetProductSignup = (productId) => {
  if (productId === DEV_PRODUCT_ID || productId === CRM_PRODUCT_ID) return true; // release Dev and CRM to all product high intent traffic

  return false;
};
