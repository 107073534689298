const { isCountryInContinent, OCEANIA } = require("../countries/countries-service");
const { USE_OLD_STORAGE_SETTINGS, WEBSITE_AB_TEST_DEBUG_COOKIE_NAME } = require("../../constants/cookies");

const { getAbTestVariantNew } = require("../new-ab-tests/new-ab-tests-service");
const { doesCountryRequireStrictCookiePolicy } = require("../../server/services/aws-service/browser-service");
const { isRunningInClient } = require("../dom-utils-service");

const {
  SKIP_SIGN_UP_STEP_IN_PRICING_PAGE_AB_TEST,
  DRIFT_PRICING_CHATBOT_CONTACT_SALES_AB_TEST,
  EXIT_POP_UP_SURVEY_AB_TEST,
  LEARN_MORE_AND_SIGNUP_BUTTON,
  LEARN_MORE_TO_PRODUCT_BUTTON,
  REVIVE_EXIT_POPUP_AB_TEST,
  PERFORMANCE_STATIC_PAGE_AB_TEST,
  BIGGER_STORAGE_IN_PRICING_PAGE_FEATURES,
  TEMPLATES_IN_HEADER_AB_TEST,
  DESKTOP_WITH_ENTERPRISE_IN_SUBMENU_TEST,
  PRICING_ENTERPRISE_CONTACT_SALES_AB_TEST,
  MOBILE_STICKY_SIGNUP_AB_TEST,
  ENRICHMENT_HOMEPAGE_SALES_CTA_AB_TEST,
  ENRICHEMNT_DRIFT_CRM_LP_AB_TEST,
  MAIN_SIGNUP_AB_TEST,
  LP_SIGNUP_POPUP_WITHOUT_ASSET_AB_TEST,
  WELCOME_BACK_PAGE_TEST,
  KEEP_REGION_TEST,
  CONTACT_SALES_TYPEFORM_EMEA,
  CONTACT_SALES_TYPEFORM_ANZ,
  CONTACT_SALES_MOBILE_TYPEFORM_TEST,
  SIGNUP_WITH_PRODUCT_PAGE_WITH_PRODUCT_RECOMMENDATION_TEST,
  HEADER_NEW_PRODUCTS_OFFERING_TEST,
  SIGNUP_WITH_PRODUCT_PAGE_WITHOUT_PRODUCT_RECOMMENDATION_TEST,
  CS_1_19_PHONE_SUPPORT_TEST,
  LANGUAGE_PICKER_IN_HEADER_TEST,
  UNLIMITED_TIME_FREE_PLAN_CTA_TEXT_TEST,
  SHOW_SUCCESS_STORIES_IN_HEADER_TEST,
  LEARN_MORE_BUTTONS_IN_HEADER_TEST
} = require("../../constants/new-ab-tests");

const shouldGetSkipSignUpStepInPricingAbTest = (abTests = [], sendBigBrainEvent = false) => {
  return getAbTestVariantNew(abTests, SKIP_SIGN_UP_STEP_IN_PRICING_PAGE_AB_TEST, sendBigBrainEvent) === "new_with_skip";
};

const shouldGetPricingChatbotContactSalesAbTest = (abTests = [], sendBigBrainEvent) => {
  return (
    getAbTestVariantNew(abTests, DRIFT_PRICING_CHATBOT_CONTACT_SALES_AB_TEST, sendBigBrainEvent) === "new_with_chat"
  );
};

const shouldSeeExitPopupSurveyAbTest = (abTests = [], sendBigBrainEvent) => {
  return getAbTestVariantNew(abTests, EXIT_POP_UP_SURVEY_AB_TEST, sendBigBrainEvent) === "new_with_survey";
};

const shouldSeeLearnMoreAndSignupButton = (abTests = [], sendBigBrainEvent = false) => {
  return (
    getAbTestVariantNew(abTests, LEARN_MORE_AND_SIGNUP_BUTTON.test_name, sendBigBrainEvent) ===
    LEARN_MORE_AND_SIGNUP_BUTTON.new_variation_name
  );
};

const shouldSeeLearnMoreToProductButton = (abTests = [], sendBigBrainEvent = false) => {
  return (
    getAbTestVariantNew(abTests, LEARN_MORE_TO_PRODUCT_BUTTON.test_name, sendBigBrainEvent) ===
    LEARN_MORE_TO_PRODUCT_BUTTON.new_variation_name
  );
};

const shouldSeeReviveExitPopupAbTest = (abTests = [], sendBigBrainEvent = false) => {
  return (
    getAbTestVariantNew(abTests, REVIVE_EXIT_POPUP_AB_TEST.test_name, sendBigBrainEvent) ===
    REVIVE_EXIT_POPUP_AB_TEST.new_variation_name
  );
};

const shouldSeeStaticPagePerformanceAbTest = (
  abTests = [],
  cloudfrontViewerCountry = null,
  sendBigBrainEvent = false
) => {
  if (doesCountryRequireStrictCookiePolicy(cloudfrontViewerCountry)) return false;
  return (
    getAbTestVariantNew(abTests, PERFORMANCE_STATIC_PAGE_AB_TEST.test_name, sendBigBrainEvent) ===
    PERFORMANCE_STATIC_PAGE_AB_TEST.new_variation_name
  );
};

const shouldShowBiggerStorageInPricingFeatures = (abTests = [], cookies = {}, sendBigBrainEvent = false) => {
  if (typeof cookies[USE_OLD_STORAGE_SETTINGS] !== "undefined") {
    return cookies[USE_OLD_STORAGE_SETTINGS] !== "true";
  }
  return (
    getAbTestVariantNew(abTests, BIGGER_STORAGE_IN_PRICING_PAGE_FEATURES.test_name, sendBigBrainEvent) ===
    BIGGER_STORAGE_IN_PRICING_PAGE_FEATURES.new_variation_name
  );
};

const shouldSeeTemplatesInHeader = (abTests = [], sendBigBrainEvent = false) => {
  return (
    getAbTestVariantNew(abTests, TEMPLATES_IN_HEADER_AB_TEST.test_name, sendBigBrainEvent) ===
    TEMPLATES_IN_HEADER_AB_TEST.new_variation_name
  );
};

const shouldSeeDesktopWithEnterpriseMenuAbTest = (abTests = [], sendBigBrainEvent = false) => {
  return (
    getAbTestVariantNew(abTests, DESKTOP_WITH_ENTERPRISE_IN_SUBMENU_TEST.test_name, sendBigBrainEvent) ===
    DESKTOP_WITH_ENTERPRISE_IN_SUBMENU_TEST.new_variation_name
  );
};

const shouldGetEnterpriseContactUsPageAbTest = (abTests = [], sendBigBrainEvent = false) => {
  return (
    getAbTestVariantNew(abTests, PRICING_ENTERPRISE_CONTACT_SALES_AB_TEST.test_name, sendBigBrainEvent) ===
    PRICING_ENTERPRISE_CONTACT_SALES_AB_TEST.new_variation_name
  );
};

const shouldHaveProductLearnMoreHeaderTest = (abTests = [], sendBigBrainEvent = false) => {
  return (
    getAbTestVariantNew(abTests, LEARN_MORE_BUTTONS_IN_HEADER_TEST.test_name, sendBigBrainEvent) ===
    LEARN_MORE_BUTTONS_IN_HEADER_TEST.new_variation_name
  );
};

const shouldGetMobileStickySignupAbTest = (abTests = [], sendBigBrainEvent = false) => {
  return (
    getAbTestVariantNew(abTests, MOBILE_STICKY_SIGNUP_AB_TEST.test_name, sendBigBrainEvent) ===
    MOBILE_STICKY_SIGNUP_AB_TEST.new_variation_name
  );
};

const shouldSeeEnrichmentHomepageSalesCtaAbTest = (abTests = [], sendBigBrainEvent = false) => {
  return (
    getAbTestVariantNew(abTests, ENRICHMENT_HOMEPAGE_SALES_CTA_AB_TEST.test_name, sendBigBrainEvent) ===
    ENRICHMENT_HOMEPAGE_SALES_CTA_AB_TEST.new_variation_name
  );
};

const shouldRenderDriftScriptCrmLpAbTest = (abTests = [], sendBigBrainEvent = false) => {
  return (
    getAbTestVariantNew(abTests, ENRICHEMNT_DRIFT_CRM_LP_AB_TEST.test_name, sendBigBrainEvent) ===
    ENRICHEMNT_DRIFT_CRM_LP_AB_TEST.new_variation_name
  );
};

const shouldRenderSignupPopupAbTest = (abTests = [], sendBigBrainEvent = false) => {
  return (
    getAbTestVariantNew(abTests, MAIN_SIGNUP_AB_TEST.test_name, sendBigBrainEvent) ===
    MAIN_SIGNUP_AB_TEST.new_variation_name
  );
};

const shouldRenderSignupPopupWithoutAssetAbTest = (abTests = [], sendBigBrainEvent = false) => {
  try {
    if (isRunningInClient() && localStorage?.getItem("signUpNoAsset") === "true") return true;
  } catch (e) {
    // nothing to dojust prevent any chance of crash.
  }
  return (
    getAbTestVariantNew(abTests, LP_SIGNUP_POPUP_WITHOUT_ASSET_AB_TEST.test_name, sendBigBrainEvent) ===
    LP_SIGNUP_POPUP_WITHOUT_ASSET_AB_TEST.new_variation_name
  );
};

const shouldGetWelcomeBackPageTest = (
  abTests = [],
  persistantCookies = {},
  sendBigBrainEvent = false,
  extraBigBrainParams = {}
) => {
  if (persistantCookies[WEBSITE_AB_TEST_DEBUG_COOKIE_NAME] === WELCOME_BACK_PAGE_TEST.test_name) return true;

  return (
    getAbTestVariantNew(abTests, WELCOME_BACK_PAGE_TEST.test_name, sendBigBrainEvent, false, extraBigBrainParams) ===
    WELCOME_BACK_PAGE_TEST.new_variation_name
  );
};

const shouldGetKeepRegionTest = (abTests = [], cloudfrontViewerCountry = null, sendBigBrainEvent = false) => {
  try {
    if (isRunningInClient() && localStorage?.getItem("is_in_keep_region_test") === "true") {
      return true;
    }
    if (isCountryInContinent(cloudfrontViewerCountry, OCEANIA)) {
      // only people from Oceanic countries should enter the test
      return (
        getAbTestVariantNew(abTests, KEEP_REGION_TEST.test_name, sendBigBrainEvent) ===
        KEEP_REGION_TEST.new_variation_name
      );
    }
  } catch (error) {
    // nothing to do - just done to protect from crashes
  }
  return false;
};

const shouldGetContactSalesTypeformEmeaTest = (abTests = [], sendBigBrainEvent = false) => {
  return (
    getAbTestVariantNew(abTests, CONTACT_SALES_TYPEFORM_EMEA.test_name, sendBigBrainEvent) ===
    CONTACT_SALES_TYPEFORM_EMEA.new_variation_name
  );
};

const shouldGetContactSalesTypeformAnzTest = (abTests = [], sendBigBrainEvent = false) => {
  return (
    getAbTestVariantNew(abTests, CONTACT_SALES_TYPEFORM_ANZ.test_name, sendBigBrainEvent) ===
    CONTACT_SALES_TYPEFORM_ANZ.new_variation_name
  );
};

const shouldGetContactSalesTypeformMobileTest = (abTests = [], sendBigBrainEvent = false) => {
  return (
    getAbTestVariantNew(abTests, CONTACT_SALES_MOBILE_TYPEFORM_TEST.test_name, sendBigBrainEvent) ===
    CONTACT_SALES_MOBILE_TYPEFORM_TEST.new_variation_name
  );
};

const shouldSendToSignupWithProductPageWithoutProductRecommendationTest = (abTests = [], sendBigBrainEvent = false) => {
  return (
    getAbTestVariantNew(
      abTests,
      SIGNUP_WITH_PRODUCT_PAGE_WITHOUT_PRODUCT_RECOMMENDATION_TEST.test_name,
      sendBigBrainEvent
    ) === SIGNUP_WITH_PRODUCT_PAGE_WITHOUT_PRODUCT_RECOMMENDATION_TEST.new_variation_name
  );
};

const shouldSendToSignupWithProductPageWithProductRecommendationTest = (abTests = [], sendBigBrainEvent = false) => {
  return (
    getAbTestVariantNew(
      abTests,
      SIGNUP_WITH_PRODUCT_PAGE_WITH_PRODUCT_RECOMMENDATION_TEST.test_name,
      sendBigBrainEvent
    ) === SIGNUP_WITH_PRODUCT_PAGE_WITH_PRODUCT_RECOMMENDATION_TEST.new_variation_name
  );
};

const shouldHaveHeaderWithNewProductsOfferingTest = (abTests = [], sendBigBrainEvent = false) => {
  return (
    getAbTestVariantNew(abTests, HEADER_NEW_PRODUCTS_OFFERING_TEST.test_name, sendBigBrainEvent) ===
    HEADER_NEW_PRODUCTS_OFFERING_TEST.new_variation_name
  );
};

const shouldHaveCS1_19PhoneSupportTest = (abTests = [], sendBigBrainEvent = false) => {
  return (
    getAbTestVariantNew(abTests, CS_1_19_PHONE_SUPPORT_TEST.test_name, sendBigBrainEvent) ===
    CS_1_19_PHONE_SUPPORT_TEST.new_variation_name
  );
};

const shouldHaveLanguagePickerInHeaderTest = (abTests = [], sendBigBrainEvent = false) => {
  return (
    getAbTestVariantNew(abTests, LANGUAGE_PICKER_IN_HEADER_TEST.test_name, sendBigBrainEvent) ===
    LANGUAGE_PICKER_IN_HEADER_TEST.new_variation_name
  );
};

const shouldGetUnlimitedTimeCTATextTest = (abTests = [], sendBigBrainEvent = false) => {
  return (
    getAbTestVariantNew(abTests, UNLIMITED_TIME_FREE_PLAN_CTA_TEXT_TEST.test_name, sendBigBrainEvent) ===
    UNLIMITED_TIME_FREE_PLAN_CTA_TEXT_TEST.new_variation_name
  );
};

const shouldShowSuccessStoriesInHeaderTest = (abTests = [], sendBigBrainEvent = false) => {
  return (
    getAbTestVariantNew(abTests, SHOW_SUCCESS_STORIES_IN_HEADER_TEST.test_name, sendBigBrainEvent) ===
    SHOW_SUCCESS_STORIES_IN_HEADER_TEST.new_variation_name
  );
};

module.exports = {
  shouldGetSkipSignUpStepInPricingAbTest,
  shouldGetPricingChatbotContactSalesAbTest,
  shouldSeeExitPopupSurveyAbTest,
  shouldSeeLearnMoreAndSignupButton,
  shouldSeeLearnMoreToProductButton,
  shouldSeeReviveExitPopupAbTest,
  shouldSeeStaticPagePerformanceAbTest,
  shouldShowBiggerStorageInPricingFeatures,
  shouldSeeTemplatesInHeader,
  shouldSeeDesktopWithEnterpriseMenuAbTest,
  shouldGetEnterpriseContactUsPageAbTest,
  shouldGetMobileStickySignupAbTest,
  shouldHaveProductLearnMoreHeaderTest,
  shouldSeeEnrichmentHomepageSalesCtaAbTest,
  shouldRenderDriftScriptCrmLpAbTest,
  shouldRenderSignupPopupAbTest,
  shouldRenderSignupPopupWithoutAssetAbTest,
  shouldGetWelcomeBackPageTest,
  shouldGetKeepRegionTest,
  shouldGetContactSalesTypeformEmeaTest,
  shouldGetContactSalesTypeformAnzTest,
  shouldGetContactSalesTypeformMobileTest,
  shouldSendToSignupWithProductPageWithoutProductRecommendationTest,
  shouldSendToSignupWithProductPageWithProductRecommendationTest,
  shouldHaveHeaderWithNewProductsOfferingTest,
  shouldHaveCS1_19PhoneSupportTest,
  shouldHaveLanguagePickerInHeaderTest,
  shouldGetUnlimitedTimeCTATextTest,
  shouldShowSuccessStoriesInHeaderTest
};
