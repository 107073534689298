import {
  OurProductFooterLinks,
  OurProductFooterLinksV2,
  OurTeamFooterLinks,
  OurTeamFooterLinksV2,
  UseCasesFooterLinks,
  UseCasesFooterLinksV2,
  HelpFooterLinks,
  ResourcesFooterLinks,
  NewSecurityFooterLinks,
  Header,
  LocalizedHeader,
  ShortHeader,
  ProductsFooterLinks,
  FeaturesFooterLinks,
  MondayFooterLinks,
  MoreByMondayFooterLinks,
  slimFooterLinks
} from "/constants/links";
import { LocalizedResourcesFooterLinks, LocalizedOurProductFooterLinksV2 } from "/constants/localized-links";
import { SUPPORTED_LOCALE_IDS } from "/constants/localization";
import englishTranslations from "services/localization/keys/all.json";
import { TRANSLATIONS_INTERNAL_ERROR } from "/constants/bigbrain-event-types";
import { trackBigBrainEventOnServer } from "/server/services/bigbrain-tracking-service/bigbrain-tracking-service";
import { getCachedData } from "/server/services/data-service/providers/base-data-provider";
import TranslationsDataProvider from "/server/services/data-service/providers/translations-data-provider";
import { HOMEPAGE_PROJECT } from "/constants/translations";

export const getFooterComponents = (localeId, abTests, forceGetAllComponents = false, additionalProps = {}) => {
  const { pageClusterConfig, useProductMiniSiteConfig } = additionalProps;
  const SlimFooterLinks = slimFooterLinks(pageClusterConfig, { useProductMiniSiteConfig });
  if (isSupportedLocale(localeId) && !forceGetAllComponents)
    return {
      MondayFooterLinks,
      FeaturesFooterLinks,
      ProductsFooterLinks,
      MoreByMondayFooterLinks,
      OurProductFooterLinks,
      OurProductFooterLinksV2: updateLocalizedStaticLinks(
        localeId,
        OurProductFooterLinksV2,
        LocalizedOurProductFooterLinksV2
      ),
      OurTeamFooterLinks,
      OurTeamFooterLinksV2,
      ResourcesFooterLinks: updateLocalizedStaticLinks(localeId, ResourcesFooterLinks, LocalizedResourcesFooterLinks),
      HelpFooterLinks,
      NewSecurityFooterLinks,
      SlimFooterLinks
    };

  return {
    MondayFooterLinks,
    FeaturesFooterLinks,
    ProductsFooterLinks,
    MoreByMondayFooterLinks,
    OurProductFooterLinks,
    OurProductFooterLinksV2: updateLocalizedStaticLinks(
      localeId,
      OurProductFooterLinksV2,
      LocalizedOurProductFooterLinksV2
    ),
    OurTeamFooterLinks,
    OurTeamFooterLinksV2,
    UseCasesFooterLinks,
    UseCasesFooterLinksV2,
    ResourcesFooterLinks: updateLocalizedStaticLinks(localeId, ResourcesFooterLinks, LocalizedResourcesFooterLinks),
    HelpFooterLinks,
    NewSecurityFooterLinks,
    SlimFooterLinks
  };
};

export const getHeaderComponents = (localeId, showShortHeader = false) => {
  if (isSupportedLocale(localeId)) return LocalizedHeader;

  return showShortHeader ? ShortHeader : Header;
};

export const isSupportedLocale = (localeId) => {
  const filteredArray = SUPPORTED_LOCALE_IDS.filter((supportedLocale) => supportedLocale === localeId);
  return filteredArray.length > 0;
};

const updateLocalizedStaticLinks = (localeId, resourceLinks, allLocalesLocalizedLinks) => {
  const localeLocalizedLinks = allLocalesLocalizedLinks[localeId];
  const localizedLinks = { ...resourceLinks.links, ...localeLocalizedLinks };
  return { ...resourceLinks, links: localizedLinks };
};

export const getTranslationsFromApi = async (localeId) => {
  if (!isSupportedLocale(localeId)) return englishTranslations;

  let translations = {};

  try {
    const provider = new TranslationsDataProvider({ localeId, project: HOMEPAGE_PROJECT });
    translations = await getCachedData(provider);
  } catch (exception) {
    trackBigBrainEventOnServer(TRANSLATIONS_INTERNAL_ERROR, {
      info1: exception.message,
      info2: localeId
    });
  }

  return { ...englishTranslations, ...translations };
};

export const translateToEnglish = (key) => {
  return englishTranslations[key] || key;
};
