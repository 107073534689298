const { trackBigBrainEventOnServer } = require("/server/services/bigbrain-tracking-service/bigbrain-tracking-service");
const { BaseDataProvider } = require("./base-data-provider");
const ProviderKeys = require('./providers-keys');

const { CLUSTER_INTERNAL_FETCH_ERROR } = require("/constants/bigbrain-event-types");

const REFRESH_INTERVAL_MINUTES = 15 * 60;
const URL_BASE = process.env.MONDAY_BASE_PATH;
const CLUSTER_CONFIG_URL_BASE = `${URL_BASE}/clusters/get-cluster-config`;

class ClusterConfigDataProvider extends BaseDataProvider {
    constructor(params){
        super(params);
    }

    key(){
        const { clusterId, subClusterId, locale } = this.params();
        return `${ProviderKeys.CLUSTER_CONFIG}_cluster_${clusterId}_subCluster_${subClusterId}_locale_${locale}`;
    }

    refreshInterval(){
        return REFRESH_INTERVAL_MINUTES;
    }

    async getData(){
        const { clusterId, subClusterId, locale } = this.params();

        try {
            const options = {
                headers: { "Content-Type": "application/json" },
                method: "GET"
            };
            const searchParams = new URLSearchParams({clusterId, subClusterId, locale})
            const url = `${CLUSTER_CONFIG_URL_BASE}?${searchParams}`;
            const res = await fetch(url, options);
    
            if (res?.status === 200) {
                const response = await res.json();
                return response;
            } else {
                throw new Error(res.statusText)
            }
        } catch (e) {
            trackBigBrainEventOnServer(CLUSTER_INTERNAL_FETCH_ERROR, { direct_object: e.message });
            console.error(e.message);
            throw e;
        }
    }
}

export default ClusterConfigDataProvider;