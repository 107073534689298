//  names of translation projects supported by cluster service
const HOMEPAGE_PROJECT = "homepage"; // All the website static translations
const GENERATOR_PROJECT = "lp_generator"; // Page translations jobs data

const ALL_TRANSLATION_PROJECTS = [HOMEPAGE_PROJECT, GENERATOR_PROJECT];
module.exports = {
  HOMEPAGE_PROJECT,
  GENERATOR_PROJECT,
  ALL_TRANSLATION_PROJECTS
};
