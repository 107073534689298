const getCachedData = async (providerInstance) => {
  const cacheService = require("../cache-service");
  const cachedProvider = cacheService.toCacheable(providerInstance);
  const data = await cachedProvider.getData();
  return data;
};

class BaseDataProvider {
  constructor(params) {
    this._params = params;
  }

  // The unique identifier of the provider
  key() {
    throw new Error("You must supply provider's key");
  }

  // The method use to fetch the data
  async getData() {
    throw new Error("You must supply provider's key");
  }

  // The parameters received for fetching the data
  params() {
    return this._params || {};
  }

  // The interval in which the data will be refreshed, in seconds
  refreshInterval() {
    return 60; // a minute
  }

  // The duration the cache value will remain valid, in seconds
  cacheExpirationTime() {
    return 60 * 60 * 24 * 7; // 7 days
  }
}

module.exports = {
  BaseDataProvider,
  getCachedData
};
