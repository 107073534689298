import { isServerSide } from "utils/general";
import { trackBigBrainEventOnServer } from "/server/services/bigbrain-tracking-service/bigbrain-tracking-service";
import { MINI_SITE_CLUSTER_NOT_VALID } from "constants/bigbrain-event-types";
import { translateToEnglish } from "services/localization/localization-service";
import { normalizePath } from "/server/services/url-service/url-service";

const getMiniSiteRootConfig = (clusterConfig, options = {}) => {
  if (options.useProductMiniSiteConfig && clusterConfig?.product_mini_site) return clusterConfig?.product_mini_site;

  return clusterConfig?.mini_site;
};

export const isMiniSiteConfigValid = (clusterConfig, options) => {
  const miniSiteConfig = getMiniSiteRootConfig(clusterConfig, options);

  const valid =
    miniSiteConfig &&
    miniSiteConfig?.logo?.src &&
    miniSiteConfig?.logo?.url &&
    miniSiteConfig?.logo?.mobileSrc &&
    miniSiteConfig?.unifiedDesktopLogo?.src &&
    miniSiteConfig?.unifiedDesktopLogo?.url &&
    validateMiniSiteLinks(miniSiteConfig?.links);

  if (!valid && isServerSide()) {
    trackBigBrainEventOnServer(MINI_SITE_CLUSTER_NOT_VALID, {
      kind: "mini_site",
      info1: clusterConfig?.name
    });
  }

  return valid;
};

const validateMiniSiteLinks = (links) => {
  if (!links || links?.length < 0) return false;
  const validLinks = links.filter((link) => link.url && link.text);

  return links.length === validLinks.length;
};

export const getMiniSiteLogo = (clusterConfig, options) => {
  const miniSiteConfig = getMiniSiteRootConfig(clusterConfig, options);
  return miniSiteConfig?.logo;
};

export const getMiniSiteTemplatesPageLink = (clusterConfig, options) => {
  const miniSiteConfig = getMiniSiteRootConfig(clusterConfig, options);
  const links = miniSiteConfig?.links;
  if (!validateMiniSiteLinks(miniSiteConfig?.links)) return null;

  const templatesLink = links.filter(
    (link) => translateToEnglish(link.text) === translateToEnglish("header.templates")
  )[0]; // returns undefined if doesn't exist
  return templatesLink?.url; // can return null
};

export const getMiniSitePricingPageLink = (clusterConfig, options) => {
  const miniSiteConfig = getMiniSiteRootConfig(clusterConfig, options);
  const links = miniSiteConfig?.links;
  if (!validateMiniSiteLinks(miniSiteConfig?.links)) return null;

  const pricingLink = links.filter((link) => link.isPricingPage)[0]; //returns undefined if doesn't exist
  return pricingLink?.url; //can return null
};

export const shouldHideGeneralPricingInMinisite = (clusterConfig, options) => {
  return !!getMiniSitePricingPageLink(clusterConfig, options);
};

export const getMiniSiteUnifiedDesktopLogo = (clusterConfig, options) => {
  const miniSiteConfig = getMiniSiteRootConfig(clusterConfig, options);
  return miniSiteConfig?.unifiedDesktopLogo;
};

export const getMiniSiteLinks = (clusterConfig, options = {}) => {
  const miniSiteConfig = getMiniSiteRootConfig(clusterConfig, options);
  let links = miniSiteConfig?.links;
  if (options.onlyUnifiedLinks) {
    links = links.filter((link) => !link.removeFromUnified);
  }
  return links;
};

export const getDynamicAssetConfigForPath = (clusterConfig, pagePath) => {
  if (!pagePath) return null;
  const normalizedPagePath = normalizePath(pagePath);
  const config = clusterConfig?.dynamic_asset?.[normalizedPagePath];
  if (config?.src && config?.alt) {
    return config;
  }
  return null;
};

export const getDynamicTitleConfigForPath = (clusterConfig, pagePath) => {
  if (!pagePath) return null;
  const normalizedPagePath = normalizePath(pagePath);
  return clusterConfig?.dynamic_title?.[normalizedPagePath];
};

export const getDynamicSubTitleConfigForPath = (clusterConfig, pagePath) => {
  if (!pagePath) return null;
  const normalizedPagePath = normalizePath(pagePath);
  return clusterConfig?.dynamic_subtitle?.[normalizedPagePath];
};

export const getClusterDesktopLogo = (clusterConfig, options) => {
  //currently each cluster with a minisite get the minisite logo
  return getMiniSiteUnifiedDesktopLogo(clusterConfig, options);
};

export const updateClusterFooterLinks = (clusterConfig, footerLinks, options) => {
  const clusterPricingPageLink = getMiniSitePricingPageLink(clusterConfig, options);
  if (clusterPricingPageLink) {
    if (footerLinks?.MondayFooterLinks?.links) {
      //Refactor together with entire footer links issue and unify with short footer + make generic
      footerLinks.MondayFooterLinks.links["footer.pricing"] = clusterPricingPageLink;
    }
  }
};

export const updateClusterShortFooterLinks = (clusterConfig, shortFooterLinks, options) => {
  const clusterPricingPageLink = getMiniSitePricingPageLink(clusterConfig, options);
  if (clusterPricingPageLink) {
    const pricingItem = shortFooterLinks.find((item) => item.title === "footer.pricing");
    pricingItem.url = clusterPricingPageLink;
  }
};
