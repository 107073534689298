const CLUSTER = "cluster";
const SUB_CLUSTER = "subcluster";
const PREVENT_FREE_TIER_PARAM = "prevent_free_tier";
const SUPPORTED_OVERRIDE_QUERY_PARAMS = [CLUSTER, SUB_CLUSTER];
const FORCE_PRICING = "force_pricing";
const FORCE_CURRENCY = "force-currency";
const DYNAMIC_ASSET_PARAM = "dimg";
const ACTIVE_TAB_ID = "ati";

module.exports = {
  CLUSTER,
  SUB_CLUSTER,
  PREVENT_FREE_TIER_PARAM,
  SUPPORTED_OVERRIDE_QUERY_PARAMS,
  FORCE_PRICING,
  FORCE_CURRENCY,
  DYNAMIC_ASSET_PARAM,
  ACTIVE_TAB_ID
};
