const { trackBigBrainEventOnServer } = require("../../bigbrain-tracking-service/bigbrain-tracking-service");
const { BaseDataProvider } = require("./base-data-provider");
const ProviderKeys = require("./providers-keys");

const REFRESH_INTERVAL_MINUTES = 15 * 60;
const URL_BASE = process.env.MONDAY_BASE_PATH;
const TRANSLATIONS_URL_BASE = `${URL_BASE}/localization/get`;

class TranslationsDataProvider extends BaseDataProvider {
  key() {
    const { localeId, project } = this.params();
    return `${ProviderKeys.TRANSLATIONS}_locale_${localeId}_project_${project}`;
  }

  refreshInterval() {
    return REFRESH_INTERVAL_MINUTES;
  }

  async getData() {
    const { localeId, project } = this.params();

    try {
      const options = {
        headers: { "Content-Type": "application/json" },
        method: "GET"
      };

      const url = `${TRANSLATIONS_URL_BASE}/${localeId}/${project}`;
      const res = await fetch(url, options);

      if (res && res.status === 200) {
        const response = await res.json();
        return response;
      }
      throw new Error(res.statusText);
    } catch (e) {
      trackBigBrainEventOnServer("translations_internal_fetch_error", { direct_object: e.message });
      console.error(e.message);
      throw e;
    }
  }
}

module.exports = TranslationsDataProvider;
