const { BIGBRAIN_TRACKING_URL } = require("../../../constants/analytics");
const { logException } = require("../../logging/logger");
const { LOGGER_TAG_PARAM } = require("../../logging/consts");

//  can import with "import" only if you are in a "page", otherwise, should use "require"
const trackBigBrainEventOnServer = async (name, bbArgs = {}) => {
  try {
    const data = { name, source: "monday_homepage", ...bbArgs };
    if (process.env.NODE_ENV !== "production") {
      console.log("Bigbrain event: ", data);
      return;
    }
    await fetch(BIGBRAIN_TRACKING_URL, {
      method: "post",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" }
    });
  } catch (e) {
    logException({
      externalMessage: "trackBigBrainEventOnServer exception",
      e,
      extraLogParams: { [LOGGER_TAG_PARAM]: "bigbrain" }
    });
    console.error(e);
  }
};

module.exports = { trackBigBrainEventOnServer };
