const { differenceInSeconds } = require("date-fns");
const { getAsync, setAsync, delAsync } = require("../redis-service/redis-service");
const { initLogger } = require("../logger-service/logger-service");

const toCacheable = (provider) => {
  // Provider and cache properties
  const key = provider.key();
  const params = provider.params();
  const refreshInterval = provider.refreshInterval();
  const cacheExpirationTime = provider.cacheExpirationTime();

  // Cache keys
  const CACHE_PREFIX = "homepage::cache_service";
  const cacheKey = `${CACHE_PREFIX}::${key}`;

  // Logger init
  const logger = initLogger();
  const metadata = { provider: key, params, cacheKey };

  const shouldFetch = (value) => {
    const lastUpdatedAt = value && value.lastUpdatedAt;
    return differenceInSeconds(new Date(), lastUpdatedAt) > refreshInterval;
  };

  const invalidate = async () => {
    try {
      await delAsync(cacheKey);
    } catch (error) {
      logger.error({ ...metadata, error }, `cacheService:::invalidate failed`);
      throw error;
    }
  };

  const fetch = async () => {
    const data = await provider.getData();
    const cacheData = JSON.stringify({ data, lastUpdatedAt: new Date() });
    await setAsync(cacheKey, cacheData, "EX", cacheExpirationTime);

    return data;
  };

  const getData = async () => {
    try {
      const cacheValue = await getAsync(cacheKey);
      let response = null;

      // Cache hit
      if (cacheValue) {
        logger.info(metadata, "cacheService:::fetch cache hit");
        let value;
        let forceFetch = false;

        // Try to parse the value in cache, if it fails, try to regenerate in order to get a new copy
        // Might happen with corrupted json received from getData
        try {
          value = JSON.parse(cacheValue);
          response = value.data;
        } catch (error) {
          logger.error({ ...metadata, error }, `cacheService:::fetch forced regenerating because of invalid json`);
          forceFetch = true;
        }

        // Check if should be refreshed, even if value exists in cache
        if (forceFetch || shouldFetch(value)) {
          logger.info({ ...metadata, forceFetch }, "cacheService:::fetch re-fetching");
          // Should be parallel (better move to sidekiq or any other background job)
          try {
            response = await fetch();
          } catch (error) {
            logger.error({ ...metadata, forceFetch, error }, `cacheService:::fetch failed while re-fetching`);
          }
        }
      }
      // Cache miss
      else {
        logger.info(metadata, `cacheService:::fetch cache miss`);
        response = await fetch();
      }

      return response;
    } catch (error) {
      logger.error(error, `cacheService:::getData failed`);
      throw error;
    }
  };

  return {
    getData,
    invalidate
  };
};

module.exports = {
  toCacheable
};
