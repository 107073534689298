const { getLogger } = require("@mondaydotcomorg/monday-logger");
const { initLogger: initTestLogger } = require("./__mocks__/logger-service");

const initLogger = () => {
  if (process.env.NODE_ENV === "test") {
    return initTestLogger();
  }

  return getLogger();
};

module.exports = { initLogger };
