const { isDevelopment } = require("../environment-service");
const { isRunningInClient } = require("../dom-utils-service");

const AB_TEST_VARIANTS_HEADER = "x-abtest-variants";
const { getHasFreeTier } = require("../pricing/free-tier-service");
const { getHasStudentPlan } = require("../pricing/pricing-model/student-service");
const {
  HAS_STUDENT_PLAN_INDICATION_COOKIE_NAME,
  HAS_FREE_TIER_INDICATION_COOKIE_NAME
} = require("../../constants/cookies");
const { FORCE_PRICING, PREVENT_FREE_TIER_PARAM } = require("../../constants/query-params");

const getBigBrainEventSentVarName = (testName) => {
  return `bb_event_sent_ab_test_${testName}`;
};

const testEventSent = (testName) => {
  const eventSentVar = getBigBrainEventSentVarName(testName);
  return !!window[eventSentVar];
};

const setTestEventSent = (testName) => {
  const eventSentVar = getBigBrainEventSentVarName(testName);
  window[eventSentVar] = true;
};

const setHotjarTestVariant = (testName, testVariant) => {
  if (!isRunningInClient) return;
  if ((window && !window.hj) || !testVariant) return;
  if (window.path !== "/") return; // temp specific code to record only homepage

  const tag = `${testName}-${testVariant}`;
  window.hj("trigger", `${tag}`);
};

// can be used both in Server and Clients side render - event sent only when in client
const getAbTestVariantNew = (
  allAbTests,
  testName,
  sendBigBrainEvent = true,
  sendHotjarEvent = false,
  extraBigBrainParams = {}
) => {
  const test = allAbTests.filter((abTest) => abTest.name === testName)[0];
  if (!test) return null;

  if (sendBigBrainEvent && isRunningInClient() && !testEventSent(testName)) {
    const { trackEvent } = require("../bigbrain-service");
    trackEvent(testName, { ab_test: test.variant, ...extraBigBrainParams });
    setTestEventSent(testName);
    if (sendHotjarEvent) setHotjarTestVariant(testName, test.variant);
  }
  return test.variant;
};

const fireEventsOnServerRedirectTests = (allAbTests) => {
  //THIS FUNCTION IS USED TO SEND BIG BRAIN EVENT WHEN RUNNING SERVER REDIRECT TESTS
  //CREATE AN ABTEST WITH exact_pages that include only the original and redirect page and then call:
  //getAbTestVariantNew(allAbTests, TEST_NAME, true);
};

const isInTestWithNewVariant = (abTestData, abTests = [], sendBigBrainEvent = false) => {
  return getAbTestVariantNew(abTests, abTestData.test_name, sendBigBrainEvent) === abTestData.new_variation_name;
};

const isInTestWithOldVariant = (abTestData, abTests = [], sendBigBrainEvent = false) => {
  return getAbTestVariantNew(abTests, abTestData.test_name, sendBigBrainEvent) === abTestData.old_variation_name;
};

const isInTest = (abTestData, abTests = [], sendBigBrainEvent = false) => {
  return !!getAbTestVariantNew(abTests, abTestData.test_name, sendBigBrainEvent);
};

const getHasFreeTierByRequest = (req) => {
  if (!req) return false;

  const { path } = req;
  const freeTierForcePrevent = req.query && req.query[PREVENT_FREE_TIER_PARAM];
  const forcePricing = req.query && req.query[FORCE_PRICING];
  const hasFreeTierCookie = req.cookies[HAS_FREE_TIER_INDICATION_COOKIE_NAME];
  const hasStudentPlanCookie = req.cookies[HAS_STUDENT_PLAN_INDICATION_COOKIE_NAME];
  const hasStudentPlan = getHasStudentPlan({ forcePricing, hasStudentPlanCookie });

  return getHasFreeTier({
    asPath: path,
    freeTierForcePrevent,
    hasStudentPlan,
    hasFreeTierCookie
  });
};

const DEV_AB_TESTS = [
  {
    name: "crm_pricing_page_sections_test",
    variant: "new_with_crm_sections"
  },
  {
    name: "homepage_v10_pricing_version_ab_test",
    variant: "new_with_v10"
  },
  {
    name: "contact_sales_submit_notice_ab_test",
    variant: "new_with_submit_notice"
  },
  {
    name: "keep_region_au_ab_test",
    variant: "new_with_keep_region"
  },
  {
    name: "contact_sales_typeform_emea",
    variant: "new_with_typeform"
  },
  {
    name: "contact_sales_typeform_anz",
    variant: "new_with_typeform"
  },
  {
    name: "header_new_products_offering_more_clickable_test",
    variant: "old_header_products_offering"
  },
  {
    name: "cs_1_19_phone_support_test",
    variant: "new_with_phone_support_group"
  },
  {
    name: "language_picker_in_header_test_v2",
    variant: "new_with_language_picker_in_header"
  },
  {
    name: "pricing_page_sub_title_v2_ab_test",
    variant: "new_pricing_with_sub_title_v2"
  },
  {
    name: "unlimited_time_free_plan_cta_text_test",
    variant: "new_unlimited_time_cta"
  }
];

const getAbTestsFromRequest = (req) => {
  if (isDevelopment) return JSON.stringify(DEV_AB_TESTS);
  return req.headers[AB_TEST_VARIANTS_HEADER];
};

const getAllAbTestsVariantsFromRequest = (req) => {
  const abTestsHeader = req && getAbTestsFromRequest(req);
  if (abTestsHeader) {
    try {
      return JSON.parse(abTestsHeader);
    } catch (err) {
      console.error("Error Parsing AB Test Header string:", abTestsHeader, " with error: ", err);
      return [];
    }
  } else {
    return [];
  }
};

module.exports = {
  getAbTestVariantNew,
  fireEventsOnServerRedirectTests,
  getAllAbTestsVariantsFromRequest,
  isInTest,
  isInTestWithNewVariant,
  isInTestWithOldVariant,
  getHasFreeTierByRequest
};
