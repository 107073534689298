//  names of clusters
const MARKETING = "marketing";
const CRM = "crm";
const NON_PROFIT = "ngo";
const OPERATIONS = "operations";
const DESIGN_AND_CREATIVE = "design_and_creative";
const SOFTWARE = "tech";
const HR = "hr";
const IT = "information_technology";
const EDUCATION = "education";
const CONSTRUCTION = "construction";
const FINANCE = "finance";

// SubCluster
const WORK_DOCS_SUB_CLUSTER = "workdocs";

/****  names of use cases - Start ****/
const PROJECT_MANAGEMENT_USE_CASE = "project_management";

// work management
const PROJECT_AND_TASK_MANAGEMENT = "project_and_task_management";
const WORKLOAD_MANAGEMENT = "workload_management";
const CLIENT_PROJECTS = "client_projects";
const GOALS_AND_OKRS = "goals_and_okrs";
const REQUEST_MANAGEMENT = "request_management";

// crm
const SALES_PIPELINE = "sales_pipeline";
const CONTACT_MANAGEMENT = "contact_management";
const LEADS_CAPTURING = "leads_capturing";
const LEAD_MANAGEMENT = "lead_management";
const PERFORMANCE_TRACKING = "performance_tracking";
const EMAIL_SYNC = "email_sync";

// dev
const SPRINT_MANAGEMENT = "sprint_management";
const BUGS_TRACKING = "bugs_tracking";
const SCRUM_SOFTWARE = "scrum_software";
const FEATURES_BACKLOG = "features_backlog";
const FEATURE_REQUESTS = "features_requests";
const FEEDBACK_MANAGEMENT = "feedback_management";
const RELEASE_PLAN = "release_plan";
// dev - not supported yet
const SPRINT_RETROSPECTIVE = "sprint_retrospective";
const ROADMAP_PLANNING = "roadmap_planning";

/****  names of use cases - End ****/

const DEFAULT = "default"; // no cluster
const GENERIC_CLUSTER = "generic";

//  events where you get clusters
const HP_TAGS_SELECTION = "hp_tags_selection";

module.exports = {
  MARKETING,
  CRM,
  DEFAULT,
  GENERIC_CLUSTER,
  HP_TAGS_SELECTION,
  NON_PROFIT,
  OPERATIONS,
  DESIGN_AND_CREATIVE,
  SOFTWARE,
  HR,
  IT,
  PROJECT_MANAGEMENT_USE_CASE,
  EDUCATION,
  CONSTRUCTION,
  WORK_DOCS_SUB_CLUSTER,
  FINANCE,
  PROJECT_AND_TASK_MANAGEMENT,
  WORKLOAD_MANAGEMENT,
  CLIENT_PROJECTS,
  GOALS_AND_OKRS,
  REQUEST_MANAGEMENT,
  SALES_PIPELINE,
  CONTACT_MANAGEMENT,
  LEADS_CAPTURING,
  LEAD_MANAGEMENT,
  SPRINT_MANAGEMENT,
  BUGS_TRACKING,
  SCRUM_SOFTWARE,
  FEATURES_BACKLOG,
  FEATURE_REQUESTS,
  FEEDBACK_MANAGEMENT,
  RELEASE_PLAN,
  SPRINT_RETROSPECTIVE,
  ROADMAP_PLANNING,
  PERFORMANCE_TRACKING,
  EMAIL_SYNC
};
