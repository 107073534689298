const getFileExtension = (filename) => {
  if (!filename) return null;
  if (filename.indexOf(".") < 0) return null;

  return filename.split(".").pop().toLowerCase();
};

const removeFileExtension = (filename) => {
  if (filename.indexOf(".") < 0) return filename;

  return filename.substring(0, filename.lastIndexOf("."));
};

const replaceFileExtension = (fileName, newExtension) => {
  const pos = fileName.lastIndexOf(".");
  const untilPos = pos < 0 ? fileName.length : pos;
  return fileName.substring(0, untilPos) + `.${newExtension}`;
};

module.exports = {
  getFileExtension,
  replaceFileExtension,
  removeFileExtension
};
