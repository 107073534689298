const DODGER_BLUE_COLOR_NAME = "dodger-blue";
const SPACE_BLUE_COLOR_NAME = "space-blue";
const SIGN_UP_WITH_PRODUCTS_COLOR_NAME = "sign-up-with-products-background-color-name";
const SPACE_BLUE_GRADIANT_COLOR_NAME = "space-blue-gradiant";
const SPACE_BLUE_VIDEO_ONLY_COLOR_NAME = "space-blue-video-only";
const SPACE_BLUE_VIDEO_ONLY_DESKTOP_COLOR_NAME = "space-blue-video-only-desktop";
const WHITE_COLOR_NAME = "white";
const IRIS_COLOR_NAME = "iris";
const BLUE_COLOR_NAME = "blue";
const RIVERSTONE_COLOR_NAME = "riverstone";
const PROJECTS_ORANGE_COLOR_NAME = "projects-orange";
const CRM_GREEN_COLOR_NAME = "crm-green";
const MARKETING_RED_COLOR_NAME = "marketing-red";
const DEV_GREEN_COLOR_NAME = "dev-green";
const WORK_OS_IRIS_COLOR_NAME = "workos-iris";
const LIGHT_PROJECTS_ORANGE_COLOR_NAME = "projects-orange-light";
const LIGHT_CRM_GREEN_COLOR_NAME = "crm-green-light";
const LIGHT_MARKETING_RED_COLOR_NAME = "marketing-red-light";
const LIGHT_DEV_GREEN_COLOR_NAME = "dev-green-light";
const LIGHT_WORK_OS_IRIS_COLOR_NAME = "workos-iris-light";
const DARK_PROJECTS_ORANGE_COLOR_NAME = "projects-orange-dark";
const DARK_CRM_GREEN_COLOR_NAME = "crm-green-dark";
const DARK_MARKETING_RED_COLOR_NAME = "marketing-red-dark";
const DARK_DEV_GREEN_COLOR_NAME = "dev-green-dark";
const DARK_WORK_OS_IRIS_COLOR_NAME = "workos-iris-dark";
const BACKGROUND_GRAY_COLOR_NAME = "background-gray";
const GRADIANT_WORKOS_IRIS_COLOR_NAME = "gradiant-workos-iris";
const GRADIANT_WORKOS_IRIS_HOVER_COLOR_NAME = "gradiant-workos-iris-hover";
const GRADIANT_BRAND_GRAY_COLOR_NAME = "gradiant-brand-gray";
const WORKFORMS_RED_COLOR_NAME = "workforms-red";
const LIGHT_BLUE_VIDEO_BACKGROUND_COLOR_NAME = "light-blue-video-background";
const PEACH_COLOR_NAME = "peach";
const TEAL_COLOR_NAME = "teal";
const GHOST_WHITE_COLOR_NAME = "ghost-white";
const ELEVATE_BANNER_GRADIANT_COLOR_NAME = "background-elevate";
const ELEVATE_BUTTON_GRADIANT_COLOR_NAME = "button-elevate";
const REACT_MODAL_BACKGROUND_COLOR_NAME = "react_modal_background";
const TRANSPARENT_COLOR_NAME = "transparent";

const PROJECTS_BLUE_COLOR_NAME = "projects-blue"; // DEPRECATED
const LIGHT_PROJECTS_BLUE_COLOR_NAME = "projects-blue-light"; // DEPRECATED
const DARK_PROJECTS_BLUE_COLOR_NAME = "projects-blue-dark"; // DEPRECATED
const DEV_ORANGE_COLOR_NAME = "dev-orange"; // DEPRECATED
const LIGHT_DEV_ORANGE_COLOR_NAME = "dev-orange-light"; // DEPRECATED
const DARK_DEV_ORANGE_COLOR_NAME = "dev-orange-dark"; // DEPRECATED

//new colors - rebranding
//Brand & wm colors
const BRAND_WORK_MANAGEMENT_PRIMARY_COLOR = "Brand & WM Primary Color";
const BRAND_WORK_MANAGEMENT_PRIMARY_COLOR_NAME = "brand-wm-primary-color";
const BRAND_WORK_MANAGEMENT_LIGHTER_TINT_01 = "Brand & WM Lighter tint 01";
const BRAND_AND_WORK_MANAGEMENT_LIGHT_TINT_02 = "Brand & WM Light tint 02";
const BRAND_AND_WORK_MANAGEMENT_DARK_TINT_01 = "Brand & WM Dark tint 01";
const BRAND_AND_WORK_MANAGEMENT_DARKER_TINT_02 = "Brand & WM Darker tint 02";
const BRAND_PRIMARY_YELLOW = "Brand Primary Yellow";
const BRAND_YELLOW_DARK_TINT_01 = "Brand Yellow (dark) Tint 01";
const BRAND_YELLOW_DARKER_TINT_02 = "Brand Yellow (darker) Tint 02";
const BRAND_PRIMARY_RED = "Brand Primary Red";
const BRAND_RED_DARK_TINT_01 = "Brand Red (dark) Tint 01";
const BRAND_RED_DARKER_TINT_02 = "Brand Red (darker) Tint 02";
const BRAND_PRIMARY_GREEN = "Brand Primary Green";
const BRAND_GREEN_DARK_TINT_01 = "brand-green-dark-tint-01";
const BRAND_GREEN_DARK_TINT_01_COLOR_NAME = "brand-green-dark-tint-01";
const BRAND_GREEN_DARKER_TINT_02 = "Brand Green (darker) Tint 02";
const BRAND_DARK_COLOR = "Dark";

//The variable names "BRAND_BLACK_BACKGROUND_COLOR" and "BRAND_BLACK_TEXT_COLOR" were accidentally duplicated due to a mistake.
//However, the names serve different purposes, with "Black" indicating a darker shade for background elements and "black" representing a standard text color.
const BRAND_BLACK_BACKGROUND_COLOR = "Black";
const BRAND_BLACK_TEXT_COLOR = "black";

//Sales & crm colors
const CRM_PRIMARY_COLOR = "CRM Primary color";
const CRM_LIGHTER_TINT_01 = "CRM Lighter tint 01";
const CRM_LIGHT_TINT_02 = "CRM Light tint 02";
const CRM_DARK_TINT_01 = "CRM Dark tint 01";
const CRM_DARK_TINT_01_COLOR_NAME = "crm-dark-tint-01";
const CRM_DARKER_TINT_02 = "CRM Darker tint 02";

//Dev colors
const DEV_PRIMARY_COLOR = "Dev Primary color";
const DEV_LIGHTER_TINT_01 = "Dev Lighter Tint 01";
const DEV_LIGHT_TINT_02 = "Dev Light Tint 02";
const DEV_DARK_TINT_01 = "Dev Dark Tint 01";
const DEV_DARKER_TINT_02 = "Dev Darker Tint 02";

//Marketer colors
const MARKETER_PRIMARY_COLOR = "Marketer Primary Color";
const MARKETER_LIGHTER_TINT_01 = "Marketer Lighter Tint 01";
const MARKETER_LIGHT_TINT_02 = "Marketer Light Tint 02";
const MARKETER_DARK_TINT_01 = "Marketer Dark Tint 01";
const MARKETER_DARKER_TINT_02 = "Marketer Darker Tint 02";

//Projects colors
const PROJECTS_PRIMARY_COLOR = "Projects Primary Color";
const PROJECTS_LIGHTER_TINT_01 = "Projects Lighter Tint 01";
const PROJECTS_LIGHT_TINT_02 = "Projects Light Tint 02";
const PROJECTS_DARK_TINT_01 = "Projects Dark Tint 01";
const PROJECTS_DARKER_TINT_02 = "Projects Darker Tint 02";
const PROJECTS_DARKEST_TINT_03 = "Projects Darkest Tint 03";

//Platform colors
const UI_BORDER_LIGHT_COLOR_NAME = "UI Border Light";

const SNOW_GREY = "snow";

export {
  DODGER_BLUE_COLOR_NAME,
  SPACE_BLUE_COLOR_NAME,
  SIGN_UP_WITH_PRODUCTS_COLOR_NAME,
  WHITE_COLOR_NAME,
  IRIS_COLOR_NAME,
  BLUE_COLOR_NAME,
  RIVERSTONE_COLOR_NAME,
  PROJECTS_BLUE_COLOR_NAME,
  PROJECTS_ORANGE_COLOR_NAME,
  MARKETING_RED_COLOR_NAME,
  CRM_GREEN_COLOR_NAME,
  DEV_ORANGE_COLOR_NAME,
  DEV_GREEN_COLOR_NAME,
  WORK_OS_IRIS_COLOR_NAME,
  LIGHT_PROJECTS_BLUE_COLOR_NAME,
  LIGHT_PROJECTS_ORANGE_COLOR_NAME,
  LIGHT_MARKETING_RED_COLOR_NAME,
  LIGHT_CRM_GREEN_COLOR_NAME,
  LIGHT_DEV_ORANGE_COLOR_NAME,
  LIGHT_DEV_GREEN_COLOR_NAME,
  LIGHT_WORK_OS_IRIS_COLOR_NAME,
  DARK_PROJECTS_BLUE_COLOR_NAME,
  DARK_PROJECTS_ORANGE_COLOR_NAME,
  DARK_MARKETING_RED_COLOR_NAME,
  DARK_CRM_GREEN_COLOR_NAME,
  DARK_DEV_ORANGE_COLOR_NAME,
  DARK_DEV_GREEN_COLOR_NAME,
  DARK_WORK_OS_IRIS_COLOR_NAME,
  BACKGROUND_GRAY_COLOR_NAME,
  BRAND_BLACK_TEXT_COLOR,
  SPACE_BLUE_GRADIANT_COLOR_NAME,
  GRADIANT_WORKOS_IRIS_COLOR_NAME,
  GRADIANT_WORKOS_IRIS_HOVER_COLOR_NAME,
  GRADIANT_BRAND_GRAY_COLOR_NAME,
  WORKFORMS_RED_COLOR_NAME,
  SPACE_BLUE_VIDEO_ONLY_COLOR_NAME,
  SPACE_BLUE_VIDEO_ONLY_DESKTOP_COLOR_NAME,
  LIGHT_BLUE_VIDEO_BACKGROUND_COLOR_NAME,
  PEACH_COLOR_NAME,
  TEAL_COLOR_NAME,
  GHOST_WHITE_COLOR_NAME,
  ELEVATE_BANNER_GRADIANT_COLOR_NAME,
  ELEVATE_BUTTON_GRADIANT_COLOR_NAME,
  REACT_MODAL_BACKGROUND_COLOR_NAME,
  TRANSPARENT_COLOR_NAME,
  BRAND_WORK_MANAGEMENT_PRIMARY_COLOR,
  BRAND_WORK_MANAGEMENT_PRIMARY_COLOR_NAME,
  BRAND_WORK_MANAGEMENT_LIGHTER_TINT_01,
  BRAND_AND_WORK_MANAGEMENT_LIGHT_TINT_02,
  BRAND_AND_WORK_MANAGEMENT_DARK_TINT_01,
  BRAND_AND_WORK_MANAGEMENT_DARKER_TINT_02,
  BRAND_PRIMARY_YELLOW,
  BRAND_YELLOW_DARK_TINT_01,
  BRAND_YELLOW_DARKER_TINT_02,
  BRAND_PRIMARY_RED,
  BRAND_RED_DARK_TINT_01,
  BRAND_RED_DARKER_TINT_02,
  BRAND_PRIMARY_GREEN,
  BRAND_GREEN_DARK_TINT_01,
  BRAND_GREEN_DARK_TINT_01_COLOR_NAME,
  BRAND_GREEN_DARKER_TINT_02,
  BRAND_DARK_COLOR,
  BRAND_BLACK_BACKGROUND_COLOR,
  CRM_PRIMARY_COLOR,
  CRM_LIGHTER_TINT_01,
  CRM_LIGHT_TINT_02,
  CRM_DARK_TINT_01,
  CRM_DARK_TINT_01_COLOR_NAME,
  CRM_DARKER_TINT_02,
  DEV_PRIMARY_COLOR,
  DEV_LIGHTER_TINT_01,
  DEV_LIGHT_TINT_02,
  DEV_DARK_TINT_01,
  DEV_DARKER_TINT_02,
  MARKETER_PRIMARY_COLOR,
  MARKETER_LIGHTER_TINT_01,
  MARKETER_LIGHT_TINT_02,
  MARKETER_DARK_TINT_01,
  MARKETER_DARKER_TINT_02,
  PROJECTS_PRIMARY_COLOR,
  PROJECTS_LIGHTER_TINT_01,
  PROJECTS_LIGHT_TINT_02,
  PROJECTS_DARK_TINT_01,
  PROJECTS_DARKER_TINT_02,
  PROJECTS_DARKEST_TINT_03,
  UI_BORDER_LIGHT_COLOR_NAME,
  SNOW_GREY
};
