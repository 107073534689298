import FacebookLogo from "static/svg/social/facebook_icon.svg";
import TwitterLogo from "static/svg/social/twitter_icon.svg";
import LinkedinLogo from "static/svg/social/linkedin_icon_v3.svg";
import YoutubeLogo from "static/svg/social/youtube.svg";
import InstagramLogo from "static/svg/social/instagram.svg";
import TikTokLogo from "static/svg/social/tiktok.svg";

import FacebookLogoV2 from "static/svg/social/facebook_logo_v2.svg";
import TwitterLogoV2 from "static/svg/social/twitter_logo_v2.svg";
import LinkedinLogoV2 from "static/svg/social/linkedin_logo_v2.svg";
import YoutubeLogoV2 from "static/svg/social/youtube_logo_v2.svg";
import InstagramLogoV2 from "static/svg/social/instagram_logo_v2.svg";
import TikTokLogoV2 from "static/svg/social/tiktok_logo_v2.svg";
import RedditLogoV2 from "static/svg/social/reddit_logo_v2.svg";

import AppStore from "static/svg/social/app_store.svg";
import GooglePlay from "static/svg/social/google_play.svg";

import FacebookColorfulLogo from "static/svg/social/facebook_colorful_icon.svg";
import TwitterLogoCorolfulLogo from "static/svg/social/twitter_colorful_icon.svg";
import YoutubeLogoCorolfulLogo from "static/svg/social/youtube_colorful_icon.svg";
import LinkedinLogoCorolfulLogo from "static/svg/social/linkedIn_colorful_icon.svg";
import InstagramCorolfulLogo from "static/svg/social/instagram_colorful_icon_v4.svg";

import { getPlatformLinkByEnvironment } from "constants/platform-links";
import { getMiniSitePricingPageLink } from "client/services/cluster-config-service/cluster-config-service";

export const MONDAY_LOGO_SRC = "/static/img/monday-logo-x2.png";
export const MONDAY_LOGO_WITHOUT_TEXT = "/static/img/monday_logo_m.png";
export const MONDAY_LOGO_SMALL_SRC = "/static/img/monday-logo-small.png";
export const MONDAY_WELCOME_NEW_JOINERS_LOGO_SRC = "/static/img/monday-welcome-new-joiners-logo-x2.png";

export const ROOT_LINK = "/";

export const MONDAY_DEVELOPERS_LOGO_SRC = "/static/img/monday_developers_logo.png";
export const MONDAY_DEVELOPERS_LINK = "/developers/apps/manage"; // TODO: Update this to main developers page once we move v1 API from there

// Generator
export const GENERATOR_HELP_CENTER_LINK = "/dynamic/templates-generator/help-center";

// Our product
export const PRODUCT_PAGE_LINK = "/product";
export const STORIES_PAGE_LINK = "/why-monday/stories";
export const REVIEWS_PAGE_LINK = "/reviews";
export const PRICING_PAGE_LINK = "/pricing";
export const CONTACT_US_FROM_HEADER_PAGE_LINK = "/sales/contact-us?from=header&source=Website%20-%20Contact%20Sales";
export const PARTNERS_PAGE_LINK = "/partnership";
export const BUILDERS_CERTIFICATIONS_PAGE_LINK = "/p/certificate/";
export const ESG_PAGE_LINK = "/p/esg/";
export const AFFILIATES_PAGE_LINK = "/affiliate-program";
export const FIND_A_PARTNER_LINK = "/p/find-a-partner/";
export const TEMPLATE_CENTER_PAGE_LINK = "/templates";

export const INTEGRATIONS_PAGE_LINK = "/integrations";
export const INTEGRATIONS_SOFTWARE_SECTION_HASH_NAME = "software_development";
export const INTEGRATIONS_SOFTWARE_SECTION_1ST_ROW_HASH_NAME = "dev1";
export const INTEGRATIONS_MARKETING_SECTION_HASH_NAME = "marketing";
export const INTEGRATIONS_MARKETING_SECTION_1ST_ROW_HASH_NAME = "marketing1";
export const INTEGRATIONS_CRM_SECTION_HASH_NAME = "crm";
export const INTEGRATIONS_CRM_SECTION_1ST_ROW_HASH_NAME = "crm1";
export const INTEGRATIONS_CRM_SECTION_2ND_ROW_HASH_NAME = "crm2";
export const INTEGRATIONS_CRM_SECTION_3RD_ROW_HASH_NAME = "crm3";
export const INTEGRATIONS_TEAM_FAVORITES_SECTION_HASH_NAME = "team-favorites";
export const INTEGRATIONS_TEAM_FAVORITES_SECTION_1ST_ROW_HASH_NAME = "general1";
export const INTEGRATIONS_TEAM_FAVORITES_SECTION_2ND_ROW_HASH_NAME = "general2";
export const INTEGRATIONS_TEAM_FAVORITES_SECTION_3RD_ROW_HASH_NAME = "general3";

export const APPS_MARKETPLACE_LINK = "/marketplace";
export const APPS_DEVELOPER_LINK = "/appdeveloper";
export const WHY_MONDAY_PAGE_LINK = "/why-monday";
export const CUSTOMERS_PAGE_LINK = "/why-monday/stories";
export const DEVELOPERS_PAGE_LINK = "//developers.monday.com";
export const STUDENTS_PAGE_LINK = "/lp/students";
export const ENTERPRISE_PAGE_LINK = "/enterprise";
export const SMB_PAGE_LINK = "/smb";
export const CONTACT_SALES_PAGE_LINK = "/sales/contact-us";
export const CONTACT_SALES_FROM_FOOTER_PAGE_LINK = `${CONTACT_SALES_PAGE_LINK}?from=footer`;
export const CONTACT_SALES_FROM_TAGS_PAGE_LINK = `${CONTACT_SALES_PAGE_LINK}?from=tags`;

export const AUTOMATIONS_PAGE_LINK = "/features/automations";
export const GANTT_PAGE_LINK = "/features/gantt";
export const KANBAN_PAGE_LINK = "/features/kanban";
export const FILES_PAGE_LINK = "/features/files";
export const FORMS_PAGE_LINK = "/features/forms";
export const DASHBOARDS_PAGE_LINK = "/features/dashboards";

// Our team
export const ABOUT_US_PAGE_LINK = "/p/about/";
export const MONDAY_STORIES_PAGE = "/why-monday/stories";
export const CONTACT_US_PAGE_LINK = "/help";
export const CAREERS_PAGE_LINK = "/careers";
export const MONDAY_U_LINK = "//www.monday-u.com";
export const BLOG_PAGE_LINK = "//monday.com/blog";
export const IN_THE_NEWS_LINK = "/p/news/";
export const INVESTOR_RELATIONS_LINK = "//ir.monday.com";

// Examples
export const TASK_MANAGEMENT_EXAMPLE_LINK = "/s/task-management-software";
export const ALL_USE_CASES_LINK = "/s/software-uses";

// Help
export const GLOBAL_EVENTS_PAGE_LINK = "/p/events-hub/";
export const WEBINARS_PAGE_LINK = "/webinars";
export const GUIDES_PAGE_LINK = "//support.monday.com/hc/en-us/categories/360000104059-Guides";
export const PROFESSIONAL_SERVICES_PAGE_LINK = "/partners/professional-services";
export const MONDAY_AUTH_DOMAIN = getPlatformLinkByEnvironment("//auth.monday.com");
export const LOGIN_PAGE_LINK = `${MONDAY_AUTH_DOMAIN}/login`;
export const COMMUNITY_LINK = "//community.monday.com";
export const HELP_CENTER_LINK = "/helpcenter/";
export const SUPPORT_PAGE_LINK = HELP_CENTER_LINK;

// Product signup
export const CRM_PRODUCT_SIGN_UP_PAGE_LINK = `${MONDAY_AUTH_DOMAIN}/p/crm/users/sign_up_new`;
export const MARKETER_PRODUCT_SIGN_UP_PAGE_LINK = `${MONDAY_AUTH_DOMAIN}/p/marketing/users/sign_up_new`;
export const DEV_PRODUCT_SIGN_UP_PAGE_LINK = `${MONDAY_AUTH_DOMAIN}/p/software/users/sign_up_new`;
export const PROJECTS_PRODUCT_SIGN_UP_PAGE_LINK = `${MONDAY_AUTH_DOMAIN}/p/project_management/users/sign_up_new`;

export const SIGNUP_WITH_PRODUCT_PAGE_LINK = "/sign-up-with-product";
export const PRODUCT_ID_PARAM_NAME = "recommendedProduct";

// Security
export const ISO_PAGE_LINK = "/trustcenter/iso";
export const GDPR_PAGE_LINK = "/terms/gdpr";
export const HIPAA_PAGE_LINK = "https://support.monday.com/hc/en-us/articles/360006506699";

export const ALL_TERMS_PAGE_LINK = "/trustcenter";
export const PRIVACY_POLICY_LINK = "/terms/privacy";
export const LEGAL_PORTAL_LINK = "/l/";

export const SOC2_PAGE_LINK = "/terms/soc2";

// Other
export const VERIFY_EMAIL_LINK = `${MONDAY_AUTH_DOMAIN}/verify_email`;
export const NEW_SOFT_SIGNUP_LINK = `${MONDAY_AUTH_DOMAIN}/users/sign_up_new`;
export const SIGNUP_WITH_GOOGLE_LINK = `${MONDAY_AUTH_DOMAIN}/users/sessions/oauth2_login?new_account=true&oauth2_provider=google_oauth2_provider`;

export const getPlatformAddSolutionLink = (solutionId) => {
  // signs up with template if no account or adds template to your existing account
  return getPlatformLinkByEnvironment(`//auth.monday.com/solutions/add_solution?solution_id=${solutionId}`);
};

export const ANDROID_APP_DOWNLOAD_LINK = "https://play.google.com/store/apps/details?id=com.monday.monday";
export const IOS_APP_DOWNLOAD_LINK = "https://itunes.apple.com/app/id1290128888";
export const STATUS_LINK = "https://status.monday.com";
export const STARTUP_FOR_STARTUP_LINK = "https://www.startupforstartup.com/ww/";

// Community
export const COMMUNITY_TERMS_OF_SERVICE = "http://www.monday.com/terms/stories";
export const COMMUNITY_PRIVACY = "http://www.monday.com/privacy";

// use cases
export const CLIENT_PROJECTS_USE_CASES_LINK = "/use-cases/Client-Project-Management";
export const CREATIVE_AND_AGENCIES_USE_CASES_LINK = "/use-cases/Creative-Projects-And-Agencies";
export const PRODUCT_MANAGEMENT_USE_CASES_LINK = "/use-cases/product-management-software";
export const AGILE_PROJECTS_USE_CASES_LINK = "/use-cases/agile-project-management";
export const REMOTE_WORK_USE_CASES_LINK = "/use-cases/remote-work";
export const ORDERS_USE_CASES_LINK = "/use-cases/Production-Management-Tool";
export const HR_USE_CASES_LINK = "/use-cases/hr-management-software";
export const TEAM_MANAGEMENT_USE_CASES_LINK = "/use-cases/Team-management";
export const MEDIA_AND_PRODUCTION_LINK = "/use-cases/media-production";
export const IT_OPERATIONS_USE_CASES_LINK_V2 = "/use-cases/it-operations"; // NEW
export const TASK_MANAGEMENT_USE_CASES_LINK = "/use-cases/task-management";
export const INVENTORY_WORKFLOW_LINK = "/inventory-tracking";
export const OPERATIONS_CLUSTER_LINK = "/operations";
export const WORKDOCS_PAGE_LINK = "/workdocs";

// mini site
export const WORK_MANAGEMENT_MINI_SITE_LINK = "/work-management";
export const MARKETING_MANAGEMENT_MINI_SITE_LINK = "/marketing";
export const CONSTRUCTION_MINI_SITE_LINK = "/construction";
export const CRM_MINI_SITE_LINK = "/crm";
export const PROJECT_MANAGEMENT_MINI_SITE_LINK = "/project-management";
export const SOFTWARE_MINI_SITE_LINK = "/dev";
export const EDUCATION_MINI_SITE_LINK = "/education";

// Developers
export const DEVELOPERS_API_DOCUMENTATION = "https://developer.monday.com/api-reference/docs";
export const DEVELOPERS_TRY_IT_YOURSELF = "/developers/v2/try-it-yourself";

// Apps
const DEVELOPERS_APPS_REFERENCE = "/developers/apps/overview";
export const MIRO_APP_PAGE_LINK = "/marketplace/23";
export const DOCU_GEN_APP_PAGE_LINK = "/marketplace/12";
export const EASYFORM_APP_PAGE_LINK = "/marketplace/9";
export const FIGMA_APP_PAGE_LINK = "/marketplace/10000023";
export const TRACKET_APP_PAGE_LINK = "/marketplace/10000017";
export const TEAM_VIEW_APP_PAGE_LINK = "/marketplace/131";
export const ONE_VIEW_APP_PAGE_LINK = "/marketplace/20";
export const APPROVALS_APP_PAGE_LINK = "/marketplace/10000005";

// Solutions
export const CRM_INTEGRATIONS_PAGE_LINK = "/crm/crm-integration";

// Customers
export const ZIPPO_CUSTOMER_PAGE_LINK = "/customers/zippo";
export const NISSAN_CUSTOMER_PAGE_LINK = "/nissan-smyrna";
export const SOFTWARE_AG_CUSTOMER_PAGE_LINK = "/customers/software-ag";
export const FARFETCH_CUSTOMER_PAGE_LINK = "/customers/farfetch";
export const G2_WRITE_A_REVIEW_PAGE_LINK =
  "//www.g2.com/contributor/monday-row-2022-vs?secure%5Bpage_id%5D=monday-row-2022-vs&secure%5Brewards%5D=true&secure%5Btoken%5D=adb49027b7d564dc9b56b63581a8159433d1abde141ffb934be6ab74cb2d0369";
export const EFFICIENCY_IMPACT_PAGE_LINK = "//efficiencyimpact.monday.com";

// Work OS
export const WHY_WORK_OS_LINK = "/blog/product/work-os/";

export const NGO_PAGE_LINK = "/nonprofits";

// Accessibility
export const ACCESSIBILITY_STATEMENT_LINK = "/accessibility-statement";

// Hr
export const HR_HOME_LINK = "/hr/welcometomonday/home";
export const HR_WHO_ARE_WE_LINK = "/hr/welcometomonday/whoweare";
export const HR_MY_TEAMS_LINKS_LINK = "/hr/welcometomonday/myteam";
export const HR_KNOWLEDGE_BASE_LINK = "/hr/welcometomonday/knowledgebase";
export const HR_IMPORTANT_INFORMATION = "/hr/welcometomonday/importantinformation";
export const HR_BENEFITS_LINK = "/hr/welcometomonday/benefits";
export const HR_ONBOARDING_LINK = "/hr/welcometomonday/myonboarding";

export const HR_MANAGERS_HOME_LINK = "/hr/welcometomonday/managers/home";
export const HR_MANAGERS_KNOWLEDGE_BASE_LINK = "/hr/welcometomonday/managers/knowledgebase";
export const HR_MANAGERS_ONBOARDING_LINK = "/hr/welcometomonday/managers/myonboarding";

// Monday social
export const MONDAY_TWITTER_LINK = "https://twitter.com/mondaydotcom";
export const MONDAY_LINKEDIN_LINK = "https://www.linkedin.com/company/2525169";
export const MONDAY_FACEBOOK_LINK = "https://www.facebook.com/mondaydotcom";
export const MONDAY_YOUTUBE_LINK = "https://www.youtube.com/channel/UCA9UvBiKHly15rN8u_Km3BQ";
export const MONDAY_INSTAGRAM_LINK = "https://www.instagram.com/mondaydotcom";
export const MONDAY_TIKTOK_LINK = "https://www.tiktok.com/@mondaydotcom";
export const MONDAY_REDDIT_LINK = "https://www.reddit.com/r/mondaydotcom";

// IPO
export const RING_THE_BELL_VIDEO_LINK = "https://livestream.com/accounts/27896496/events/9678158";
export const IPO_BLOG_POST_LINK = "/blog/a-letter-from-our-founders/";
export const IPO_BLOG_POST_SPANISH_LINK =
  "/blog/es/ahora-monday-com-cotiza-en-bolsa-obten-mas-informacion-y-lee-una-carta-de-nuestros-fundadores/";
export const IPO_BLOG_POST_PORTUGESE_LINK = "/blog/pt/o-inicio-de-uma-nova-jornada-mensagem-dos-nossos-fundadores/";
export const IPO_BLOG_POST_GERMAN_LINK = "/blog/de/ein-brief-von-unseren-gruendern/"; // TODO: Change to real link
export const IPO_BLOG_POST_FRENCH_LINK =
  "/blog/fr/monday-com-est-desormais-une-entreprise-cotee-en-bourse-toutes-les-infos-et-une-lettre-de-nos-fondateurs/";

// Elevate
export const ELEVATE_MAIN_PAGE_LINK =
  "https://www.elevatebymonday.com/?utm_source=homepage&utm_medium=elevate_upper_banner&utm_campaign=homepage_banner";

// More monday products
export const WORKFORMS_HOMEPAGE_LINK = "https://workforms.monday.com";
export const CANVAS_HOMEPAGE_LINK = "//workcanvas.com";

// Platform
export const WORKLOAD_BLOG_PAGE_LINK = "/blog/project-management/workload/";
export const ENTERPRISE_AGILE_PAGE_LINK = "/enterprise-agile";

// Resources
export const DIGITAL_LIFT_LINK = "//digital-lift.org";
export const COMPARE_LINK = "/alternative";

export const ENRICHED_FOOTER_LINKS = "enriched_footer_links";

export const OurProductFooterLinks = {
  title: "footer.OurProduct",
  links: {
    "footer.product": PRODUCT_PAGE_LINK,
    "footer.stories": CUSTOMERS_PAGE_LINK,
    "footer.pricing": PRICING_PAGE_LINK,
    "footer.enterprise": ENTERPRISE_PAGE_LINK,
    "footer.partners": PARTNERS_PAGE_LINK,
    "footer.affiliates": AFFILIATES_PAGE_LINK,
    "footer.integrations": INTEGRATIONS_PAGE_LINK,
    "footer.developers": DEVELOPERS_PAGE_LINK,
    "footer.students": STUDENTS_PAGE_LINK
  }
};

export const OurProductFooterLinksV2 = {
  title: "footer.OurProduct",
  links: {
    "footer.product": PRODUCT_PAGE_LINK,
    "footer.pricing": PRICING_PAGE_LINK,
    "footer.enterprise": ENTERPRISE_PAGE_LINK,
    "footer.smb": SMB_PAGE_LINK,
    "footer.workdocs": WORKDOCS_PAGE_LINK,
    "footer.templates": TEMPLATE_CENTER_PAGE_LINK,
    "footer.appsMarketplace": APPS_MARKETPLACE_LINK,
    "footer.integrations": INTEGRATIONS_PAGE_LINK,
    "footer.developers": DEVELOPERS_PAGE_LINK,
    "footer.workOS": WHY_WORK_OS_LINK,
    "footer.ngo": NGO_PAGE_LINK
  }
};

export const ProductsFooterLinks = {
  type: ENRICHED_FOOTER_LINKS,
  title: "footer.mondayProducts",
  links: {
    "footer.mondayWorkManagement": {
      href: WORK_MANAGEMENT_MINI_SITE_LINK,
      icon: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/footer/wm_icon_footer.png"
    },
    "footer.mondaySalesCRM": {
      href: CRM_MINI_SITE_LINK,
      icon: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/footer/crm_icon_footer.png"
    },
    "footer.mondayMarketer": {
      href: MARKETING_MANAGEMENT_MINI_SITE_LINK,
      icon: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/footer/marketer_icon_footer.png"
    },
    "footer.mondayProjects": {
      href: PROJECT_MANAGEMENT_MINI_SITE_LINK,
      icon: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/footer/projects_icon_footer.png"
    },
    "footer.mondayDev": {
      href: SOFTWARE_MINI_SITE_LINK,
      icon: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/footer/monday_dev_product_management_software.png"
    }
  }
};

export const MondayFooterLinks = {
  title: "",
  hasMondayLogoTitle: true,
  links: {
    "footer.pricing": PRICING_PAGE_LINK,
    "footer.contactUs": CONTACT_US_PAGE_LINK,
    "footer.templates": TEMPLATE_CENTER_PAGE_LINK,
    "footer.smbShortened": SMB_PAGE_LINK,
    "footer.enterprise": ENTERPRISE_PAGE_LINK,
    "footer.ngo": NGO_PAGE_LINK,
    "footer.appsMarketplace": APPS_MARKETPLACE_LINK,
    "footer.support247": SUPPORT_PAGE_LINK
  }
};

const getPricingLink = (clusterConfig, options) => {
  const clusterPricingPageLink = getMiniSitePricingPageLink(clusterConfig, options);
  return clusterPricingPageLink || PRICING_PAGE_LINK;
};

export const slimFooterLinks = (clusterConfig, options) => {
  return [
    {
      title: "",
      hasMondayLogoTitle: true,
      links: {}
    },
    {
      title: "",
      type: ENRICHED_FOOTER_LINKS,
      links: {
        "footer.pricing": { href: getPricingLink(clusterConfig, options) },
        "footer.aboutUs": { href: ABOUT_US_PAGE_LINK }
      }
    },
    {
      type: ENRICHED_FOOTER_LINKS,
      links: {
        "footer.support247": { href: SUPPORT_PAGE_LINK },
        "header.customerStories": { href: STORIES_PAGE_LINK }
      }
    },
    {
      type: ENRICHED_FOOTER_LINKS,
      links: {
        "header.contactSales": {
          href: CONTACT_SALES_PAGE_LINK
        },
        "footer.enterprise": {
          href: ENTERPRISE_PAGE_LINK,
          openInNewTab: true
        }
      }
    },
    {
      type: ENRICHED_FOOTER_LINKS,
      links: {
        "footer.integrations": {
          href: CONTACT_SALES_PAGE_LINK
        },
        "footer.templates": {
          href: TEMPLATE_CENTER_PAGE_LINK,
          openInNewTab: true
        }
      }
    }
  ];
};

export const FeaturesFooterLinks = {
  title: "footer.features",
  links: {
    "footer.workdocs": WORKDOCS_PAGE_LINK,
    "footer.integrations": INTEGRATIONS_PAGE_LINK,
    "footer.automations": AUTOMATIONS_PAGE_LINK,
    "footer.files": FILES_PAGE_LINK,
    "footer.dashboards": DASHBOARDS_PAGE_LINK,
    "footer.kanban": KANBAN_PAGE_LINK,
    "footer.gantt": GANTT_PAGE_LINK
  }
};

export const MoreByMondayFooterLinks = {
  type: ENRICHED_FOOTER_LINKS,
  title: "footer.moreByMonday",
  links: {
    "footer.canvas": {
      href: CANVAS_HOMEPAGE_LINK,
      icon: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/footer/canvas_icon_footer.png",
      openInNewTab: true
    },
    "footer.workForms": {
      href: WORKFORMS_HOMEPAGE_LINK,
      icon: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/footer/workforms_icon_footer.png",
      openInNewTab: true
    }
  }
};

export const OurTeamFooterLinks = {
  title: "footer.ourTeam",
  links: {
    "footer.aboutUs": ABOUT_US_PAGE_LINK,
    "footer.contactUs": CONTACT_US_PAGE_LINK,
    "footer.careers": CAREERS_PAGE_LINK,
    "footer.findAPartner": FIND_A_PARTNER_LINK,
    "footer.blog": BLOG_PAGE_LINK
  }
};

export const OurTeamFooterLinksV2 = {
  type: ENRICHED_FOOTER_LINKS,
  title: "footer.ourTeam",
  links: {
    "footer.aboutUs": {
      href: ABOUT_US_PAGE_LINK
    },
    "footer.careersWereHiring": {
      href: CAREERS_PAGE_LINK
    },
    "footer.mondayU": {
      href: MONDAY_U_LINK,
      openInNewTab: true
    },
    "footer.press": {
      href: IN_THE_NEWS_LINK
    },
    "header.customerStories": {
      href: STORIES_PAGE_LINK
    },
    "footer.partners": {
      href: PARTNERS_PAGE_LINK
    },
    "footer.esg": {
      href: ESG_PAGE_LINK
    },
    "footer.affiliates": {
      href: AFFILIATES_PAGE_LINK
    },
    "footer.digitalLift": {
      href: DIGITAL_LIFT_LINK,
      openInNewTab: true
    },
    "footer.investorRelations": {
      href: INVESTOR_RELATIONS_LINK,
      openInNewTab: true
    }
  }
};

export const UseCasesFooterLinks = {
  title: "footer.useCases",
  links: {
    "footer.taskManagement": TASK_MANAGEMENT_EXAMPLE_LINK,
    "footer.projectManagement": PROJECT_MANAGEMENT_MINI_SITE_LINK,
    "footer.clientProjects": CLIENT_PROJECTS_USE_CASES_LINK,
    "footer.creativeAgencies": CREATIVE_AND_AGENCIES_USE_CASES_LINK,
    "footer.productManagement": PRODUCT_MANAGEMENT_USE_CASES_LINK,
    "footer.agileProjects": AGILE_PROJECTS_USE_CASES_LINK,
    "footer.salesPipeline": CRM_MINI_SITE_LINK,
    "footer.allSolutions": ALL_USE_CASES_LINK
  }
};

export const UseCasesFooterLinksV2 = {
  title: "footer.useCases",
  links: {
    "footer.marketing": MARKETING_MANAGEMENT_MINI_SITE_LINK,
    "footer.projectManagement": PROJECT_MANAGEMENT_MINI_SITE_LINK,
    "footer.salesUseCase": CRM_MINI_SITE_LINK,
    "footer.developers": SOFTWARE_MINI_SITE_LINK,
    "footer.hr": HR_USE_CASES_LINK,
    "footer.itOperations": IT_OPERATIONS_USE_CASES_LINK_V2,
    "footer.operations": OPERATIONS_CLUSTER_LINK,
    "footer.construction": CONSTRUCTION_MINI_SITE_LINK
  }
};

export const UseCasesHeaderMenuLinks = {
  links: {
    "header.projectManagement": PROJECT_MANAGEMENT_MINI_SITE_LINK,
    "header.creativeAgencies": CREATIVE_AND_AGENCIES_USE_CASES_LINK,
    "header.marketingAndPr": MARKETING_MANAGEMENT_MINI_SITE_LINK,
    "header.managingClients": CLIENT_PROJECTS_USE_CASES_LINK,
    "header.productManagement": PRODUCT_MANAGEMENT_USE_CASES_LINK,
    "header.agileDevelopment": AGILE_PROJECTS_USE_CASES_LINK,
    "header.salesAndBizdev": CRM_MINI_SITE_LINK,
    "header.ordersAndProduction": ORDERS_USE_CASES_LINK,
    "header.hrAndRecruitment": HR_USE_CASES_LINK,
    "header.teamManagement": TEAM_MANAGEMENT_USE_CASES_LINK
  },
  bottomLinks: {
    "header.seeAllUseCases": ALL_USE_CASES_LINK
  }
};

export const WhyMondayHeaderMenuLinks = {
  links: {
    "header.findOutWhy": WHY_MONDAY_PAGE_LINK,
    "header.customerStories": STORIES_PAGE_LINK,
    "header.security": ALL_TERMS_PAGE_LINK,
    "header.enterprise": ENTERPRISE_PAGE_LINK
  }
};

export const WhyMondayLocalizedHeaderMenuLinks = {
  links: {
    "header.findOutWhy": WHY_MONDAY_PAGE_LINK,
    "header.security": ALL_TERMS_PAGE_LINK,
    "header.enterprise": ENTERPRISE_PAGE_LINK
  }
};

export const HelpFooterLinks = {
  title: "footer.help",
  links: {
    "footer.dailyWebinars": WEBINARS_PAGE_LINK,
    "footer.guides": GUIDES_PAGE_LINK,
    "footer.proffesionalServices": PROFESSIONAL_SERVICES_PAGE_LINK,
    "footer.support": SUPPORT_PAGE_LINK
  }
};

export const ResourcesFooterLinks = {
  type: ENRICHED_FOOTER_LINKS,
  title: "footer.resources",
  links: {
    "footer.helpCenter": {
      href: HELP_CENTER_LINK,
      openInNewTab: true
    },
    "footer.community": {
      href: COMMUNITY_LINK,
      openInNewTab: true
    },
    "footer.blog": {
      href: BLOG_PAGE_LINK,
      openInNewTab: true
    },
    "footer.webinars": {
      href: WEBINARS_PAGE_LINK
    },
    "footer.startupForStartups": {
      href: STARTUP_FOR_STARTUP_LINK,
      openInNewTab: true
    },
    "footer.globalEvents": {
      href: GLOBAL_EVENTS_PAGE_LINK
    },
    "footer.appsDevloper": {
      href: APPS_DEVELOPER_LINK,
      openInNewTab: true
    },
    "footer.findAPartner": {
      href: FIND_A_PARTNER_LINK
    },
    "footer.compare": {
      href: COMPARE_LINK
    }
  }
};

export const NewSecurityFooterLinks = {
  title: "footer.legal",
  links: {
    "footer.termsAndPrivacy": LEGAL_PORTAL_LINK,
    "footer.privacyPolicy": PRIVACY_POLICY_LINK,
    "footer.security": ALL_TERMS_PAGE_LINK
  }
};

export const SecurityLogoLinks = {
  iso: ISO_PAGE_LINK,
  soc: SOC2_PAGE_LINK,
  gdpr: GDPR_PAGE_LINK,
  hipaa: HIPAA_PAGE_LINK
};

export const SocialFooterLinks = {
  links: {
    reddit: {
      url: MONDAY_REDDIT_LINK,
      icon: RedditLogoV2,
      iconV2: RedditLogoV2,
      colorfulIcon: RedditLogoV2,
      title: "Reddit"
    },
    linkedin: {
      url: MONDAY_LINKEDIN_LINK,
      icon: LinkedinLogo,
      iconV2: LinkedinLogoV2,
      colorfulIcon: LinkedinLogoCorolfulLogo,
      title: "LinkedIn"
    },
    facebook: {
      url: MONDAY_FACEBOOK_LINK,
      icon: FacebookLogo,
      iconV2: FacebookLogoV2,
      colorfulIcon: FacebookColorfulLogo,
      title: "Facebook"
    },
    youtube: {
      url: MONDAY_YOUTUBE_LINK,
      icon: YoutubeLogo,
      iconV2: YoutubeLogoV2,
      colorfulIcon: YoutubeLogoCorolfulLogo,
      title: "Youtube"
    },
    twitter: {
      url: MONDAY_TWITTER_LINK,
      icon: TwitterLogo,
      iconV2: TwitterLogoV2,
      colorfulIcon: TwitterLogoCorolfulLogo,
      title: "twitter"
    },
    instagram: {
      url: MONDAY_INSTAGRAM_LINK,
      icon: InstagramLogo,
      iconV2: InstagramLogoV2,
      colorfulIcon: InstagramCorolfulLogo,
      title: "Instagram"
    },
    tiktok: {
      url: MONDAY_TIKTOK_LINK,
      icon: TikTokLogo,
      iconV2: TikTokLogoV2,
      colorfulIcon: TikTokLogo,
      title: "TikTok"
    }
  },
  apps: {
    ios: {
      url: IOS_APP_DOWNLOAD_LINK,
      icon: AppStore,
      title: "Download the monday iOS App from the App Store"
    },
    andriod: {
      url: ANDROID_APP_DOWNLOAD_LINK,
      icon: GooglePlay,
      title: "Download the monday android App from Google Play"
    }
  }
};

export const SideMenuDesktop = {
  links: {
    "sideMenu.product": PRODUCT_PAGE_LINK,
    "sideMenu.stories": STORIES_PAGE_LINK,
    "sideMenu.pricing": PRICING_PAGE_LINK,
    "sideMenu.careers": CAREERS_PAGE_LINK,
    "sideMenu.logIn": LOGIN_PAGE_LINK
  }
};

export const SideMenuMobile = {
  links: {
    "sideMenu.whyMonday": WHY_MONDAY_PAGE_LINK,
    "sideMenu.product": PRODUCT_PAGE_LINK,
    "sideMenu.pricing": PRICING_PAGE_LINK,
    "header.contactSales": CONTACT_SALES_PAGE_LINK,
    "sideMenu.careers": CAREERS_PAGE_LINK,
    "sideMenu.logIn": LOGIN_PAGE_LINK
  }
};

export const Phone = {
  number: "+12017784567",
  displayedNumber: "+1 (201) 778-4567"
};

export const Header = {
  links: {
    "header.product": PRODUCT_PAGE_LINK,
    "header.useCases": {
      className: "use-cases",
      menuLinks: UseCasesHeaderMenuLinks
    },
    "header.whyMonday": { menuLinks: WhyMondayHeaderMenuLinks },
    "header.pricing": PRICING_PAGE_LINK,
    "header.contactSales": CONTACT_US_FROM_HEADER_PAGE_LINK
  }
};

export const LocalizedHeader = {
  links: {
    "header.product": PRODUCT_PAGE_LINK,
    "header.whyMonday": { menuLinks: WhyMondayLocalizedHeaderMenuLinks },
    "header.pricing": PRICING_PAGE_LINK,
    "header.contactSales": CONTACT_US_FROM_HEADER_PAGE_LINK
  }
};

export const ShortHeader = {
  links: {
    "header.whyMonday": { menuLinks: WhyMondayHeaderMenuLinks },
    "header.pricing": PRICING_PAGE_LINK,
    "header.contactSales": CONTACT_US_FROM_HEADER_PAGE_LINK
  }
};

export const DevelopersDesktopHeader = {
  links: {
    "developersHeader.documentation": DEVELOPERS_API_DOCUMENTATION,
    "developersHeader.tryItYourself": DEVELOPERS_TRY_IT_YOURSELF
  }
};

export const DevelopersMobileHeader = {
  links: {
    "developersHeader.documentation": DEVELOPERS_API_DOCUMENTATION
  }
};

export const LONG_FOOTER_BOTTOM_LINKS = [
  { title: "footer.security", url: ALL_TERMS_PAGE_LINK, componentName: "FooterBottomBarLink" },
  { title: "footer.termsAndPrivacy", url: LEGAL_PORTAL_LINK, componentName: "FooterBottomBarLink" },
  { title: "footer.privacyPolicy", url: PRIVACY_POLICY_LINK, componentName: "FooterBottomBarLink" },
  { title: "footer.status", url: STATUS_LINK, componentName: "FooterBottomBarLink" }
];

export const DEVELOPER_APPS_HEADER_LINKS = [
  {
    label: "footer.community",
    url: COMMUNITY_LINK,
    desktopAlignRight: true
  },
  {
    label: "developersHeader.appsReference",
    url: DEVELOPERS_APPS_REFERENCE,
    desktopAlignRight: true
  },
  {
    label: "developersHeader.apiDocumentation",
    url: DEVELOPERS_API_DOCUMENTATION,
    desktopAlignRight: true
  },
  {
    label: "developersHeader.playGround",
    url: DEVELOPERS_TRY_IT_YOURSELF,
    desktopAlignRight: true,
    hideInMobile: true
  },
  {
    label: "sideMenu.logIn",
    url: LOGIN_PAGE_LINK,
    desktopAlignRight: true
  }
];

export const DEVELOPER_APPS_NAVIGATION_MENU_LINKS = [
  {
    title: "Getting Started",
    links: [
      { name: "Intro", href: "/developers/apps/intro" },
      { name: "Manage Your Apps", href: "/developers/apps/manage" },
      { name: "Versioning", href: "/developers/apps/versioning" },
      { name: "OAuth & Permissions", href: "/developers/apps/oauth" },
      { name: "Authentication methods", href: "/developers/apps/choosing-auth" },
      { name: "Multitenancy", href: "/developers/apps/multitenancy" }
    ]
  },
  {
    title: "Build Views & Widgets",
    links: [
      { name: "Quickstart Guide", href: "/developers/apps/quickstart-view" },
      { name: "Advanced Topics", href: "/developers/apps/advanced-view/" },
      { name: "React components", href: "https://github.com/mondaycom/monday-ui-react-core", target: "_blank" },
      { name: "Design System", href: "https://style.monday.com/", target: "_blank" }
    ]
  },
  {
    title: "Build Integration Recipes",
    links: [
      { name: "Quickstart Guide", href: "/developers/apps/quickstart-integration/" },
      { name: "Authorization", href: "/developers/apps/integration-authorization/" },
      { name: "Built-in Actions", href: "/developers/apps/monday-action-blocks/" },
      { name: "Built-in Triggers", href: "/developers/apps/monday-trigger-blocks/" },
      { name: "Custom Actions", href: "/developers/apps/custom-actions" },
      { name: "Custom Triggers", href: "/developers/apps/custom-trigger" },
      { name: "Custom Fields", href: "/developers/apps/custom-fields" },
      { name: "Item Mapping", href: "/developers/apps/dynamic-mapping" }
    ]
  },
  {
    title: "Workspace Templates",
    links: [{ name: "Quickstart Guide", href: "/developers/apps/workspace-templates/" }]
  },
  {
    title: "Release your app",
    links: [
      { name: "How to share", href: "/developers/apps/share-your-apps/" },
      { name: "Submit to marketplace", href: "/developers/apps/submit-your-app/" }
    ]
  },
  {
    title: "Additional Resources",
    links: [
      { name: "Example Apps", href: "/developers/apps/welcome-apps/" },
      {
        name: "SDK Reference",
        href: "https://github.com/mondaycom/monday-sdk-js#mondaycom-apps-framework-sdk-for-javascript",
        target: "_blank"
      },
      { name: "FAQs", href: "/developers/apps/faqs" },
      { name: "Get Help", href: "/developers/resources" }
    ]
  }
];
