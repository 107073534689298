// Returns the objects that leftArr contains and rightArr doesn't (i.e. leftArr - rightArr )
const getArraysDifference = (leftArr, rightArr) => {
  if (isEmpty(leftArr)) {
    return [];
  }
  if (isEmpty(rightArr)) {
    return leftArr.slice(); // clone
  }
  let difference = leftArr.filter((x) => rightArr.indexOf(x) == -1);
  return difference;
};

// returns a random integer between 0 and max (exclusive)
const getRandomInt = (max) => {
  return Math.floor(Math.random() * Math.floor(max));
};

// shuffles array in place
const shuffleArrayInPlace =(array)=> {
  for (var i = array.length - 1; i > 0; i--) {
      var j = getRandomInt(i + 1);
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
  }
};

/**
 * returns the nested object according to the key
 * i.e. obj = { outer: {inner: 3}}, key = "outer.inner" => return 3, key="other.inner" => return undefined
 * @param {Object} obj
 * @param {String} key
 */
const getNestedObject = function (obj, key) {
  return key.split(".").reduce((nested, currentKey) => {
    return typeof nested == "undefined" || nested === null ? nested : nested[currentKey];
  }, obj);
};

/**
 * return true if the given object is empty (empty string / empty array / empty object / null / undefined)
 * @param {*} data
 */
const isEmpty = (data) => {
  if (typeof data == "number" || typeof data == "boolean") {
    return false;
  }
  if (typeof data == "undefined" || data === null) {
    return true;
  }
  if (typeof data.length != "undefined") {
    return data.length == 0;
  }
  for (var i in data) {
    if (data.hasOwnProperty(i)) {
      return false;
    }
  }
  return true;
};

const UUID_LENGTH = 36;

const isUUID = (str) => {
  const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return !!str.match(regex);
};

const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

const isFunction = (value) => typeof value === "function";

const mapValuesAsync = (obj, asyncFn) => {
  const keys = Object.keys(obj);
  const promises = keys.map((k) => {
    return asyncFn(obj[k]).then((newValue) => {
      return { key: k, value: newValue };
    });
  });
  return Promise.all(promises).then((values) => {
    const newObj = {};
    values.forEach((v) => {
      newObj[v.key] = v.value;
    });
    return newObj;
  });
};

const newUniqueArray = (array) => {
  return [...new Set(array)];
};

module.exports = {
  getArraysDifference,
  getRandomInt,
  isEmpty,
  getNestedObject,
  isUUID,
  UUID_LENGTH,
  sleep,
  isFunction,
  mapValuesAsync,
  newUniqueArray,
  shuffleArrayInPlace
};
