import {
  SPANISH_LOCALE_ID,
  PORTUGEESE_LOCALE_ID,
  FRENCH_LOCALE_ID,
  GERMAN_LOCALE_ID,
  JAPANESE_LOCALE_ID
} from "/constants/localization";

const SPANISH_HELP_CENTER_LINK = "//support.monday.com/hc/es";
const FRENCH_HELP_CENTER_LINK = "//support.monday.com/hc/fr";
const PORTUGEESE_HELP_CENTER_LINK = "//support.monday.com/hc/es";

const SPANISH_BLOG_LINK = "//monday.com/blog/es/";
const PORTUGEESE_BLOG_LINK = "//monday.com/blog/pt/";
const FRENCH_BLOG_LINK = "//monday.com/blog/fr/";
const GERMAN_BLOG_LINK = "//monday.com/blog/de/";
const JAPANESE_BLOG_LINK = "//monday.com/blog/ja/";

const SPANISH_WORK_OS_LINK = "//monday.com/blog/es/work-os/";
const PORTUGEESE_WORK_OS_LINK = "//monday.com/blog/pt/work-os-uma-plataforma-para-o-futuro-do-trabalho/";
const FRENCH_WORK_OS_LINK = "//monday.com/blog/fr/le-work-os-lavenir-du-travail/";

export const LocalizedResourcesFooterLinks = {
  [SPANISH_LOCALE_ID]: {
    "footer.blog": {
      href: SPANISH_BLOG_LINK,
      openInNewTab: true
    },
    "footer.helpCenter": {
      href: SPANISH_HELP_CENTER_LINK,
      openInNewTab: true
    }
  },
  [PORTUGEESE_LOCALE_ID]: {
    "footer.blog": {
      href: PORTUGEESE_BLOG_LINK,
      openInNewTab: true
    },
    "footer.helpCenter": {
      href: PORTUGEESE_HELP_CENTER_LINK,
      openInNewTab: true
    }
  },
  [FRENCH_LOCALE_ID]: {
    "footer.blog": {
      href: FRENCH_BLOG_LINK,
      openInNewTab: true
    },
    "footer.helpCenter": {
      href: FRENCH_HELP_CENTER_LINK,
      openInNewTab: true
    }
  },
  [GERMAN_LOCALE_ID]: {
    "footer.blog": {
      href: GERMAN_BLOG_LINK,
      openInNewTab: true
    }
  },
  [JAPANESE_LOCALE_ID]: {
    "footer.blog": {
      href: JAPANESE_BLOG_LINK,
      openInNewTab: true
    }
  }
};

export const LocalizedOurProductFooterLinksV2 = {
  [SPANISH_LOCALE_ID]: {
    "footer.workOS": SPANISH_WORK_OS_LINK
  },
  [PORTUGEESE_LOCALE_ID]: {
    "footer.workOS": PORTUGEESE_WORK_OS_LINK
  },
  [FRENCH_LOCALE_ID]: {
    "footer.workOS": FRENCH_WORK_OS_LINK
  }
};
