class MockLogger {
  constructor() {
    this.logs = [];
  }
  info(info) {
    this.logs.push(info);
  }
  error(error) {
    this.logs.push(error);
  }
  clear() {
    this.logs = [];
  }
  getLogs() {
    return this.logs;
  }
}

const initLogger = () => {
  return new MockLogger();
};

module.exports = { initLogger };
